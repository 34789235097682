// Core react and plugins
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';

// Utilities
import Fetching from '../../utilities/fetching';
import Encryption from '../../utilities/encryption';

// State management
import useFlash from '../../store/flash';

// View components
import Signup from '../../components/account/signup';

const SignupContainer = (props) => {

    // State management
    const navigate = useNavigate();
    const [authCookie] = useCookies([process.env.REACT_APP_AUTH_COOKIE]);
    const [formData, setFormData] = useState({ firstName: "", lastName: "", email: "", password: "", confirm: "" });
    const [btnTxt, setBtnTxt] = useState("Create account");
    const [error, setError] = useState(false);
    const [verifyRequired, setVerifyRequired] = useState(false);
    const [strength, setStrength] = useState({
        score: 0,
        lc: false,
        uc: false,
        length: false,
        number: false,
        char: false,
        noDict: false,
        hasEntry: false
    });
    const [flashState, flashActions] = useFlash(); // eslint-disable-line
    const [valErrors, setValErrors] = useState(false); // eslint-disable-line
    const urlPath = useParams();

    const handleUpdate = (e) => {
        var newFormData = { ...formData };
        newFormData[e.target.name] = e.target.value;
        setFormData(newFormData);

        // Handle the password strength check
        if (e.target.name === 'password') {
            updateStrength(e.target.value);
        }
    }

    // Update the password strengthometer
    const updateStrength = (pw) => {

        console.log(pw);
        // Set up the object
        var newStrength = { ...strength };
        newStrength.score = 0;

        // Check that we have an entry
        newStrength.hasEntry = (pw.length > 0) ? true : false;

        // Is the password long enough
        newStrength.length = (pw.length > 7) ? true : false;
        if (newStrength.length) newStrength.score++;

        // Check for letters and numbers
        var i;
        var character;
        newStrength.number = false;
        newStrength.lc = false;
        newStrength.uc = false;
        for (i = 0; i < pw.length; i++) {

            character = pw.charAt(i);

            // Check for numbers
            if (character !== ' ' && !isNaN(character * 1)) {
                newStrength.number = true;
            } else {

                if (character === character.toUpperCase() && (/^[a-zA-Z0-9]*$/.test(character) === true)) {
                    // Check for uppercase letters
                    newStrength.uc = true;
                } else if (character === character.toLowerCase() && (/^[a-zA-Z0-9]*$/.test(character) === true)) {
                    // Check for lowercase letters
                    newStrength.lc = true;
                }

            }

        }
        if (newStrength.lc) newStrength.score++;
        if (newStrength.uc) newStrength.score++;
        if (newStrength.number) newStrength.score++;

        // Check for special characters
        var pw_test = pw.replace(/ /g, '');
        newStrength.char = false;
        if (/^[a-zA-Z0-9]*$/.test(pw_test) === false && pw.length > 0) {
            newStrength.char = true;
            newStrength.score++;
        }

        // Finally a dictionary check
        // var commonPws = JSON.parse('<?php echo $forbiddenWords; ?>');
        var commonPws = ["password"]
        newStrength.noDict = true;
        var lowerPw = pw.toLowerCase();
        for (var j in commonPws) {
            if (lowerPw.indexOf(commonPws[j]) > -1) {
                newStrength.noDict = false;
                break;
            }
        }
        if (newStrength.noDict) newStrength.score++;

        setStrength(newStrength);

    }

    // Submit the entered passwords
    const submit = (e) => {

        e.preventDefault();
        setBtnTxt('Creating account...');

        // Submit the login request
        fetch(process.env.REACT_APP_API_BASE + '/create-account', {
            method: 'post',
            headers: {
                "Content-type": "application/json; charset=utf-8"
            },
            body:JSON.stringify(formData)
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            setBtnTxt('Create account');
            if (data.rslt === 'success') {

                // Set a success message and tell them that they need to verify their email address
                setError({ msg: data.msg, style: 'grn' });
                setVerifyRequired(true);
                return;
            } 
            
            setError({ msg: data.msg, style: "red" });
            // Validation errors
            if (data.detail !== null) {
                let newValErrs = Fetching.processErrors(data.detail);
                setValErrors(newValErrs);
            }
        })
        .catch(function (error) {
            setBtnTxt('Create account');
            setError({ msg: "Sorry but there was a problem creating your account. Please try again.", style: "red" });
        });

    }

    // useEffect to load up the auth check when the component mounts
    /* eslint-disable */
    useEffect(() => {
        // Check whether they are already signed in - if so redirect them to the my account page 
        let authInfo = Encryption.extractHeaders(authCookie);
        if (authInfo) {
            navigate("/account");
        }
    }, []);
    /* eslint-enable */

    return (
        <Signup
            submitFunc={ submit }
            error={ error }
            valErrors={ valErrors }
            type={ urlPath.setType }
            btnTxt={ btnTxt }
            strength={ strength }
            handleUpdate={ handleUpdate }
            verifyRequired={verifyRequired }
        />
    );
}

export default SignupContainer;
