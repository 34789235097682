// Core react and plugins
import React, { useState, useEffect } from 'react';

import useFlash from '../../../store/flash';

// View components
import MenuList from './../../../components/admin/menus/menu-list';

const MenuListContainer = (props) => {

    // State management

    const [error, setError] = useState(false);
    const [flashState, flashActions] = useFlash();

    // useEffect to load up the auth check when the component mounts

    // useEffect to load up the auth check when the component mounts
    useEffect(() => {
        if (flashState.msg) {
            setError(flashState);
            flashActions.clear();
        }
    }, [setError, flashState, flashActions]);


    return (
        <MenuList  error={error}  />
    )
}

export default MenuListContainer;