import React, { Fragment } from 'react';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';
import moment from 'moment';

import Loader from '../common/loader';
import Error from '../common/forms/error';
import DownloadsContainer from '../../../containers/account/fields/downloads-container';
import { Helmet } from 'react-helmet';
import Download from './download';

const ViewApplication = (props) => {

    const categoryMap = {
        "uk-arts": "UK Arts",
        "uk-social": "UK Social Concern",
        "international": "International"
    }

    return (
        <div className="apply">
            <Helmet>
                <title>View application :: The Morel Trust</title>
            </Helmet>

            <div className="content">

                <div className="width-8">

                    <Download {...props} />
                    <h1>View application</h1>
                    <Error error={props.error} />

                    { props.loading ? (
                        <Loader />
                    ) : (
                        <Fragment>
                            { props.application.length === 0 ? (
                                <p>There has been an error loading the application details.</p>
                            ) : (
                                <Fragment>
                                    { props.application.map((question, index) => 
                                        <div key={"1-" + index} className="question-display">
                                            {question.questionType === "files" ? (
                                                <div className="file-downloads" dangerouslySetInnerHTML={{ __html: question.question }}></div>
                                            ) : (
                                                <h5>{question.question}</h5>
                                            )}

                                            { (question.questionType === "text" || question.questionType === "email") && 
                                                <div className='answer-display'>
                                                    { (typeof question.answer !== "undefined") ? question.answer : "" }
                                                </div>
                                            }

                                            { (question.questionType === "select") && 
                                                <div className='answer-display'>
                                                    { (typeof question.answer !== "undefined" && typeof categoryMap[question.answer] != "undefined") ? categoryMap[question.answer] : "" }
                                                </div>
                                            }

                                            {question.questionType === "textarea" &&
                                                <div className='answer-display new-lines'>
                                                    {(typeof question.answer !== "undefined") ? question.answer : ""}
                                                </div>
                                            }

                                            {question.questionType === "wysiwyg" &&
                                                <div className='answer-display' dangerouslySetInnerHTML={{ __html: (typeof question.answer !== "undefined") ? question.answer : "" }}></div>
                                            }

                                            {question.questionType === "files" &&
                                                <DownloadsContainer
                                                    question={question} 
                                                    value={(typeof question.answer !== "undefined") ? question.answer : []}
                                                    isAdmin={true}
                                                />
                                            }
                                        </div>
                                    )}
                                </Fragment>
                            )}
                        </Fragment>
                    )}

                </div>

                <div className="width-4 sidebar comments">
                    <h4>Comments</h4>
                    
                    { props.comments.length > 0 ? (
                        <Fragment>
                            { props.comments.map( (comment, j) => 
                                <div key={"comment-" + j} className="comment">
                                    <div className="comment-content" dangerouslySetInnerHTML={{ __html: comment.comment }}></div>
                                    <p className="sml right">{ moment(comment.createdAt).format("DD/MM/YYYY") }</p>
                                    <p className="sml author">{ comment.user.firstName } {comment.user.lastName}</p>
                                </div>
                            )}
                        </Fragment>
                    ) : (
                        <p className="empty">There are currently no comments on this application.</p>
                    )}
                </div>

                <div className="width-4 sidebar comments">
                    <h5>Add comment</h5>
                    
                    <div className="input textarea">
                        <label htmlFor='comment'>Comment</label>
                        <CKEditor
                            editor={InlineEditor}
                            data={props.comment ? props.comment : ""}
                            config={{
                                toolbar: ['heading', '|', 'Bold', 'Italic', 'Link', "Unlink", "|", 'NumberedList', 'BulletedList', 'Blockquote', '|', 'undo', 'redo'],
                                heading: {
                                    options: [
                                        { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
                                        { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' }
                                    ]
                                }
                            }}
                            onBlur={(event, editor) => {
                                const data = editor.getData();
                                props.setComment(data)
                            }}
                        />
                    </div>
                    <button onClick={() => props.addComment()}>{ props.btn }</button>
                </div>
            </div>
        </div>
    );
}

export default ViewApplication;
