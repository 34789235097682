import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import Loader from '../admin/common/loader';
import BlockBarContainer from '../../containers/admin/blocks/cms/block-bar-container';
import Permissions from '../../utilities/permissions';
import MtLogo from './../../assets/img/mt/logo.webp';
import { Helmet } from 'react-helmet';

const Account = (props) => {

    return (
        <div className="sided">
            <Helmet>
                <title>My account :: The Morel Trust</title>
            </Helmet>

            {props.authState && Permissions.actionCheck("admin", "all", props.authState.permissions) &&
                <BlockBarContainer {...props} />
            }

            <div className='logo-sxn'>
                <img src={MtLogo} alt="Morel Trust logo" />

                <nav>
                    <ul>
                        <li><Link to="/account">My Account</Link></li>
                        <li><Link to="/apply">Create Application</Link></li>
                        <li className='btm-lnk'><Link to="/u/logout">Sign out</Link></li>
                    </ul>
                </nav>
            </div>
            <div className="core">
                <div className="rght-content">

                    <h1>My applications</h1>

                    { props.loading ? (
                        <Loader />
                    ) : (
                        <Fragment>
                            <h3>Applications in progress</h3>
                            { props.progressing.length === 0 ? (
                                <p className='empty'>You have no applications in progress</p>
                            ) : (
                                <ul>
                                    { props.progressing.map((app, index) => 
                                        <li key={"p-" + index}>
                                            Application created at { moment(app.createdAt).format("HH:mm on DD/MM/YYYY")}
                                            (<Link to={"/apply/" + app.id}>Update</Link>)
                                        </li>
                                    )}
                                </ul>
                            )}

                            <h3>Submitted applications</h3>
                            { props.submitted.length === 0 ? (
                                <p className='empty'>You have no applications in progress</p>
                            ) : (
                                <ul>
                                    { props.submitted.map((app, index) => 
                                        <li key={"s-" + index}>
                                            Application created at { moment(app.createdAt).format("HH:mm on DD/MM/YYYY")} and submitted at { moment(app.submittedAt).format("HH:mm on DD/MM/YYYY")}
                                            (<Link to={"/view-application/" + app.id}>View</Link>)
                                        </li>
                                    )}
                                </ul>
                            )}
                        </Fragment>
                    )}

                </div>
            </div>
        </div>
    );
}

export default Account;

// TODO: File uploads