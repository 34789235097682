// TODO: Handling CSRF attacks?!?!
// Submit an MFA token to complete login

// Core react and plugins
import React, { useState, useEffect } from 'react';

// Utilities
import CryptoJS from 'crypto-js';
import Encryption from '../../../utilities/encryption';

// State management
import { useCookies } from 'react-cookie';
import useFlash from '../../../store/flash';

// View components
import { useLocation } from 'react-router-dom';
import AdminManage from './../../../components/admin/users/admin-manage';

const AdminManageContainer = (props) => {

    // State management
    let encSecret = process.env.REACT_APP_ENC_KEY;
    const [rdr, setRdr] = useState(false);
    const [flashState, flashActions] = useFlash(); // eslint-disable-line
    const [authCookie] = useCookies([process.env.REACT_APP_AUTH_COOKIE]);
    const [permissions,setPermissions] = useState({})
    const { pathname } = useLocation();
    const redirectUrl = "/users/dashboard"

    //pages that can be accessed by all user types
    const urlPathsToBypassSecurityCheck = {
        '/admin/manage/users/self': true
    }

    const checkUserPermissions = () =>{
        // If there is no cookie, then they are not logged in
        let authInfo = Encryption.extractHeaders(authCookie);
        if (!authInfo) {
            // Not logged in so everything is cool
            setRdr("/");
            return;
        }

        if (!authInfo.permissions.Reports && !authInfo.permissions.Manage && typeof urlPathsToBypassSecurityCheck[pathname] === 'undefined'){
            setRdr(redirectUrl)
            return
        } else {
            setPermissions(authInfo.permissions)
        }
    }

    /* eslint-disable */
    useEffect(() => {
        checkUserPermissions()
    },[])
    /* eslint-enable */

    return (
        <AdminManage
            rdr={ rdr }
            permissions={ permissions }
        />
    );
}

export default AdminManageContainer;
