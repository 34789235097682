import React, { useState, useEffect } from 'react';

// Utilities
import Fetching from '../../../utilities/fetching';
import Encryption from '../../../utilities/encryption';

// State management
import { useCookies } from 'react-cookie';

import FileInput from '../../../components/account/dynamic-fields/file-input';
import Downloads from '../../../components/account/dynamic-fields/downloads';


function DownloadsContainer(props) {

    // State management   
    const [files, setFiles] = useState([]);

    const [error, setError] = useState(false);
    const [authCookie] = useCookies([process.env.REACT_APP_AUTH_COOKIE]);

    // Load up the folders 
    let loadFiles = () => {

        if(!props.value) return;
        let fileIds = (typeof props.value == "object") ?  props.value : JSON.parse(props.value);
        if(fileIds.length === 0) return;

        let authInfo = Encryption.extractHeaders(authCookie);

        // Check the server side to see what the status of this auth is...
        var url = (typeof props.isAdmin != "undefined" && props.isAdmin) ? '/admin-specific-files' : '/specific-files';
        fetch(process.env.REACT_APP_API_BASE + url, {
            method: 'POST',
            headers: {
                "Content-type": "application/json",
                'api-token': authInfo.apiToken,
                'api-key': authInfo.apiKey
            },
            body: JSON.stringify(fileIds)
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            if (data.rslt === 'success') {
                setFiles(data.detail);
            }

        })
        .catch(function (error) {
            
        });
    }

    // Generate a pre-signed download URL and download the file 
    let download = (e, id) => {

        e.preventDefault();

        let authInfo = Encryption.extractHeaders(authCookie);

        // Get the pre-signed URL from the server 
        var uri = (typeof props.isAdmin != "undefined" && props.isAdmin) ? '/admin-download/' : '/download/';
        fetch(process.env.REACT_APP_API_BASE + uri + id, {
            method: 'GET',
            headers: {
                "Content-type": "application/json",
                'api-token': authInfo.apiToken,
                'api-key': authInfo.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            if (data.rslt === 'success') {
                window.open(data.detail, '_blank');
                return;
            }
            setError({ msg: data.msg, style: "red" });

        })
        .catch(function (error) {
            setError({ msg: "There was an error downloading the file. Please try again later.", style: "red" });
        });

    }



    /* eslint-disable */
    useEffect(() => {
        loadFiles();
    }, [props.value]);
    /* eslint-enable */

    return (
        <Downloads 
            files={files}
            label={props.label}
            error={error}
            download={ download }
        />
    );
}

export default DownloadsContainer;

