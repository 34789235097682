// Core react and plugins
import React, { Fragment } from 'react';

import PageSidebar from '../common/page-sidebar';

// Components
import Error from './../common/forms/error';


// Component class itself
function Instagram(props) {

    return (
        <Fragment>

            <PageSidebar />

            <div className="width-9">
                <h1>Instagram feed</h1>

                <Error error={props.error} />

                { props.haveToken ? ( 
                    <Fragment>
                        <p>You currently have an integration set up with your Instagram account. If you would like to update it (to change the linked account) you can do so using the button below.</p>
                        <button onClick={props.triggerRdr}>{props.btnTxt}</button>
                    </Fragment>
                ):(
                    <Fragment>
                        <p>In order to activate the Instagram block on your website, you will need to authorise the website to access your Instagram account.</p>
                        <p>Click the button below to be redirected to Instagram to grant the appropriate permissions.</p>
                        <button onClick={props.triggerRdr}>{props.btnTxt}</button>
                    </Fragment>
                )}

            </div>
        </Fragment>
    )

}

export default Instagram;