import React from 'react';
import ValidationError from '../../admin/common/forms/validation-error';

const SelectInput = (props) => {

    return (
        <div className="input text">
            <label htmlFor={props.name}>{props.label}</label>
            <select name={props.name} id={props.name} value={props.value} onChange={(e) => props.handleResponse(props.name, e.target.value, true) }>
                <option value="">Select an option</option>
                { props.options.map((option, index) =>
                    <option key={index} value={option.value}>{option.name}</option>
                )}
            </select>
            <ValidationError errors={props.valErrors} fieldName={props.name} />
        </div>
    );
}

export default SelectInput;
