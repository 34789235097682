// Sign out a user + end the session
// Core react and plugins
import React, { useState, useEffect } from 'react';

// Utilities
import Fetching from '../../../../utilities/fetching';
import CryptoJS from 'crypto-js';

// State management
import useAuth from '../../../../store/auth';
import { useCookies } from 'react-cookie';

// View components
import Logout from './../../../../components/admin/users/auth/logout';

const LogoutContainer = () => {

    // State management
    let encSecret = process.env.REACT_APP_ENC_KEY;
    const [rdr, setRdr] = useState(false);
    const [authState, authActions] = useAuth(); // eslint-disable-line
    const [authCookie, setCookie, removeCookie] = useCookies([process.env.REACT_APP_AUTH_COOKIE]); // eslint-disable-line

    // Logout (handled upon component mount)
    const logout = () => {

        // If there is no cookie, then they are not logged in
        if (!authCookie || Object.keys(authCookie).length === 0 || authCookie === "undefined" || typeof authCookie.lwb == "undefined") {
            // Not logged in so everything is cool
            removeCookie(process.env.REACT_APP_AUTH_COOKIE, { path: '/', secure: true, sameSite: true });
            setRdr("/");
            return;
        }

        // Get the auth cookie
        var reformData = CryptoJS.AES.decrypt(authCookie.lwb, encSecret)
        reformData = JSON.parse(reformData.toString(CryptoJS.enc.Utf8));

        // Submit the logout request
        fetch(process.env.REACT_APP_API_BASE + '/users/logout', {
            method: 'get',
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
                'api-token': reformData.apiToken,
                'api-key': reformData.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            // Remove all API tokens etc
            // Clear the cookie
            removeCookie(process.env.REACT_APP_AUTH_COOKIE, { path: '/', secure: true, sameSite: true });

            // Clear the auth state
            authActions.clearAuth();

            // Return to the login screen
            setRdr("/");

        })
        .catch(function (error) {
            // Clear the cookie
            removeCookie(process.env.REACT_APP_AUTH_COOKIE, { path: '/', secure: true, sameSite: true });
            // Clear the auth state
            authActions.clearAuth();
            // Return to the login screen
            setRdr("/");
        });

    }

    // useEffect to load up the auth check when the component mounts
    /* eslint-disable */
    useEffect(() => {
        logout();
    }, []);
    /* eslint-enable */

    return (
        <Logout
            rdr={ rdr }
        />
    );
}

export default LogoutContainer;
