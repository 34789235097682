// Core react and plugins
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

// Components
import Error from './../admin/common/forms/error';

// Component class itself
const Activate = (props) => {


        return (

            <div className={ "signup smlr-sxn" }>

                { props.activated ? (
                    <Fragment>
                        <h1>Activate your account</h1>
                        <Error error={props.error} />
                        <p>Thanks for activating your account. You can now get started on your application.</p>
                        <Link to="/apply" className="btn">Apply now</Link>
                    </Fragment>
                ) : (
                    <div className="verify-required">
                        <h1>Activation required</h1>
                        <p>Please check your emails for an activation link</p>
                        <Error error={ props.error } />
                    </div>
                )}

            </div>

        );

}

export default Activate;