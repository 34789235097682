// Core react and plugins
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

// Utilities
import Fetching from './../../../utilities/fetching';
import Encryption from '../../../utilities/encryption';

// State management
import { useCookies } from 'react-cookie';
import useFlash from './../../../store/flash';

import ViewApplication from '../../../components/admin/applications/view';

const ApplicationViewContainer = () => {

    // State management
    const { aid } = useParams();

    const [loading, setLoading] = useState(false); // eslint-disable-line
    const [error, setError] = useState(false);
    const [btn, setBtn] = useState("Add comment"); // eslint-disable-line
    const [comment, setComment] = useState(""); // eslint-disable-line
    const [comments, setComments] = useState([]); // eslint-disable-line
    const [application, setApplication] = useState([]);
    const [authCookie] = useCookies([process.env.REACT_APP_AUTH_COOKIE]);
    const [archiveDate, setArchiveDate] = useState(new Date());

    let navigate = useNavigate();

    let loginUrl = "/";
    let redirectUrl = '/admin/dashboard'

    // Check that we have a partially complete auth status (based upon the cookie)
    let loadApplication = (isSearch) => {

        let authInfo = Encryption.extractHeaders(authCookie);

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + '/admin-applications/view/' + aid, {
            method: 'get',
            headers: {
                "Content-type": "application/json; charset=utf-8",
                'api-token': authInfo.apiToken,
                'api-key': authInfo.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            // Set the state for the users
            setLoading(false);
            if (data.rslt === "success" && typeof data.detail !== "undefined") {
                setApplication(data.detail);
                return;
            }

            setError({ msg: data.msg, style: "red" });

        })
        .catch(function (error) {
            setError({ msg: "There was an error loading the application. Please return to the application list and try accessing it again.", style: "red" });
            navigate(loginUrl);
        });
    }

    // Comment on an application 
    const addComment = () => {

        let authInfo = Encryption.extractHeaders(authCookie);

        // Set up the comment object
        let commentObj = {
            comment: comment
        }
        setBtn("Adding comment...");

        // Do the fetch request to send the comment 
        fetch(process.env.REACT_APP_API_BASE + '/admin-applications/comment/' + aid, {
            method: 'post',
            headers: {
                "Content-type": "application/json; charset=utf-8",
                'api-token': authInfo.apiToken,
                'api-key': authInfo.apiKey
            },
            body: JSON.stringify(commentObj)
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            // Set the state for the users
            setLoading(false);
            setBtn("Add comment");
            if (data.rslt === "success" && typeof data.detail !== "undefined") {
                loadComments();
                setComment("");
                return;
            }

            setError({ msg: data.msg, style: "red" });

        })
        .catch(function (error) {
            setError({ msg: "There was an error loading the application. Please return to the application list and try accessing it again.", style: "red" });
            navigate(loginUrl);
        });

    }

    // Comment on an application 
    const loadComments = () => {

        let authInfo = Encryption.extractHeaders(authCookie);

        // Do the fetch request to send the comment 
        fetch(process.env.REACT_APP_API_BASE + '/admin-applications/comments/' + aid, {
            method: 'get',
            headers: {
                "Content-type": "application/json; charset=utf-8",
                'api-token': authInfo.apiToken,
                'api-key': authInfo.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            // Set the state for the users
            if (data.rslt === "success" && typeof data.detail !== "undefined") {
                setComments(data.detail);
                return;
            }

            setError({ msg: data.msg, style: "red" });

        })
        .catch(function (error) {
            setError({ msg: "There was an error loading the comments for this application. Please return to the application list and try accessing it again.", style: "red" });
        });

    }

    /* eslint-disable */
    useEffect(() => {
        document.title = 'View application :: Morel Trust';
        loadApplication();
        loadComments();
    },[])
    /* eslint-enable */

    return (
        <ViewApplication
            error={ error }
            application={ application }
            loading={loading}
            archiveDate={archiveDate}
            setArchiveDate={setArchiveDate}
            comment={comment}
            setComment={setComment}
            addComment={addComment}
            comments={comments}
            btn={btn}
        />
    );
}

export default ApplicationViewContainer;
