import { Fragment } from "react";

function ErrorPage() {

    return (
        <Fragment>
            <div className='content err-page'>
                    <div className="width-6 centred centre">
                    <h1>Page not found</h1>
                    <p>We are really sorry but the page you are looking for does not exist - it has either moved or never existed.</p>
                    <p>Please use the menu below to find what you are looking for.</p>
                </div>
            </div>
        </Fragment>
    );
}

export default ErrorPage;
