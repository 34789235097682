import React, {  Fragment } from 'react';

function Password(props){

    return (
        <Fragment>
            <div className="input password">
                <label htmlFor={props.id}>{props.label}</label>
                <input type="password" id={props.id} autoComplete="password" onChange={(e) => props.handleChange(props.fieldName, e.target.value)} />
            </div>
        </Fragment>
    )
}

export default Password
