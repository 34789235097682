import React, { useState, useEffect } from 'react';
import EditImg from '../../../components/admin/common/forms/edit-img';

// Utilities
import Fetching from '../../../utilities/fetching';


function EditImgContainer(props) {

    const [imageUrl, setImageUrl] = useState([]);
    const [imageId, setImageId] = useState(props.imageId);
    const [isOpen, setIsOpen] = useState(false);

    const toggleImageManager = (e, size) => {
        if (e) e.preventDefault();
        setIsOpen(!isOpen);
    }

    const selectImage = (image) => {
        if (typeof props.updateField !== "undefined") {
            props.updateField(props.fieldName, image.id, true);
        }
        setImageId(image.id);
        toggleImageManager(false);
    }

    // Check that we have a partially complete auth status (based upon the cookie)
    let loadImage = () => {

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + '/images-get/' + imageId + "/" + props.requiredSize, {
            method: 'GET',
            headers: {
                "Content-type": "application/json"
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            if (data.rslt === "success") {
                setImageUrl(data.detail.imgUrl);
            }
                
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    // useEffect to load up the auth check when the component mounts
    /* eslint-disable */
    useEffect(() => {
        if (imageId) {
            loadImage();
        }
    }, [imageId]);
    /* eslint-enable */

    return (
        <EditImg
            isOpen={isOpen}
            toggleImageManager={toggleImageManager}
            selectImage={selectImage}
            imageUrl={imageUrl}
            {...props}
        />
    );
}

export default EditImgContainer;

