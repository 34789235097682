import { Link } from 'react-router-dom';
import DisplayImageContainer from '../../../containers/images/display-image-container';
import ExtractContent from '../../../utilities/extract-content';
import DisplayFile from '../../files/display-file';

function LeftImageShort(props) {

    let content = ExtractContent.block(props.block);
    if (!content) return "";

    return (
        <div className={"left-img short " + content['side-type']}>
            { content['side-type'] === "img" &&
                <DisplayImageContainer
                    imageId={content["img"]}
                    size="half"
                    class="img-left-hang"
                    isBgImage={true}
                />
            }

            {content['side-type'] === "map" &&
                <div className="half-side left map" dangerouslySetInnerHTML={{ __html: content.map }}></div>
            }

            {content['side-type'] === "video" &&
                <div className="half-side left video" dangerouslySetInnerHTML={{ __html: content.video }}></div>
            }


            <div className="content">
                <section className="width-6 right align-left written-content">
                    <div className="title-block">
                        { content.title !== "" &&
                            <h3>{content.title}</h3>
                        }
                        {content.subtitle !== "" &&
                            <h4>{content.subtitle}</h4>
                        }
                    </div>
                    
                    <div dangerouslySetInnerHTML={{ __html: content.content }}></div>

                    {typeof content["btn-txt"] != "undefined" && typeof content["btn-lnk"] != "undefined" &&
                        <Link className="btn sml" to={content["btn-lnk"]}>{content["btn-txt"]}</Link>
                    }

                    <DisplayFile files={typeof content["files"] !== "undefined" ? content["files"] : []} />
                </section>
            </div>
        </div>
    );
}

export default LeftImageShort;
