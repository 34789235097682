// Core react and plugins
import React, { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import CreateBlockInputContainer from './../../../containers/admin/blocks/cms/create-block-input-container';
import DraggableBlockContainer from './../../../containers/admin/blocks/cms/draggable-block-container';
import Lightbox from './../../admin/common/lightbox';

// Component class itself
// class BlockBar extends Component {
const BlockBar = (props) => {

    const navigate = useNavigate();

    // Set up the block style options
    var bos = false;
    if (typeof props.blockOptions != "undefined" && typeof props.page != "undefined" && typeof props.blockOptions[props.page.template] != "undefined") {
        bos = props.blockOptions[props.page.template];
    }

    // Set up a list of blocks that can be deleted 
    var delBlocks = [];
    var b;
    for (var j in props.blockBarItems) {
        b = props.blockBarItems[j];
        delBlocks.push({ id: b.id, style: b.blockStyle.charAt(0).toUpperCase() + b.blockStyle.slice(1), posn: b.sortOrder,label:b.blockLabel });
    }

    return (
        <Fragment>
            <Lightbox {...props} />
        
        <div className="cms-block-bar" ref={props.wrapperRef}>

            <ul>
                <li><button title="CMS Dashboard" className={"dashboard"} onClick={() => navigate("/admin/applications")}>CMS dashboard</button></li>
                {bos &&
                    <li><button title="Edit core page details" className={"edit"} onClick={() => navigate("/admin/pages" + props.page.slug)}>Edit page content</button></li>
                }
                {bos && typeof bos == "object" ? (
                    <li>
                        <button title="Create block" className={"create " + props.createClass} onClick={() => props.openCreateBlockMenu()}>Create block</button>
                        <ul className={ props.optsStatus }>
                        { bos.map ( (opt, i) => {
                            let editLbContent = <CreateBlockInputContainer createBlock={props.createBlock} isEdit={ true } blockStyle={opt} { ...props } />;
                            return (
                            <li key={ 'bopts-' + i }>
                                {/* <button className="cms-btn create" onClick={(e) => this.props.loadLightbox(e, "Edit folder", editLbContent)}>{this.props.current ? 'Edit "' + this.props.title + '"' : 'Edit'}</button> */}
                            <button className={"block-option"} onClick={(e) => props.loadLightbox(e, "Create Block Label", editLbContent,opt)}>{ opt.charAt(0).toUpperCase() + opt.slice(1) }</button>
                            </li>
                            )
                        })}

                        </ul>
                    </li>
                ) : ""}

                {delBlocks.length > 0 ? (
                <li>
                    <button title="Reorder blocks" className={"reorder " + props.reorderClass} onClick={() => props.openReorderBlockMenu()}>Reorder blocks</button>
                    <ul className={"reorder-list " + props.reorderStatus}>
                    {delBlocks.map((opt, k) => {
                        
                        return  <DraggableBlockContainer key={'blopts-' + opt.id} id={opt.id+'-'+k} index={k} opt={opt} moveBlock={props.moveBlock} saveBlockReorder={props.saveBlockReorder}/>
                            
                        })
                    }
                    </ul>
                </li>
                ) : ""}

                { delBlocks.length > 0 ? (
                <li>
                    <button title="Delete block" className={"delete " + props.deleteClass} onClick={() => props.openDeleteBlockMenu(0)}>Delete block</button>
                    <ul className={props.delStatus}>
                    {delBlocks.map((opt, k) =>
                        <li key={'blopts-' + k}>
                        <button className={"block-option"} onClick={() => props.deleteBlock(opt.id)}>{ opt.style } (Posn: { opt.posn })</button>
                        </li>
                    )}
                    </ul>
                </li>
                ) : ""}

                {bos &&
                    <li><button title="Publish changes" className={"publish " + props.publishClass} onClick={ () => props.publishChanges() }>Publish page updates</button></li>
                }
            </ul>

        </div>
        </Fragment>
    );


}

export default BlockBar;
