import React, { useRef } from 'react';

// Components
import { useDrag, useDrop } from 'react-dnd';


// Hook itself
const DraggablePageContainer = (props) => {

    const { id, index, movePage,isInMenuList } = props;

    const ref = useRef(null);
    /* eslint-disable */
    const [{ handlerId }, drop] = useDrop({
        accept: 'PAGE',
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            const isDragInMenuList = item.isInMenuList;

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                //if in the same list
                if(isDragInMenuList === isInMenuList){
                    return;
                }
                
            }

            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();

            // Figure out whether this is a child or parent option
            const hoverClientX = clientOffset.x - hoverBoundingRect.left;
            var isChild = false;
            if (hoverClientX > 42) {
                isChild = true;
            }

            // Was the item a child before (i.e. is there a change in status that needs to be handled)
            var wasChild = false;
            if (item.parentId > 0) {
                wasChild = true;
            }

            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            // Handle the instance where we are simply indenting something
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY && isChild === wasChild) {
                return;
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY && isChild === wasChild) {
                return;
            }
        
            // Time to actually perform the action
            movePage(dragIndex, hoverIndex,isDragInMenuList,isInMenuList, isChild, item.page);

            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
            item.isInMenuList = isInMenuList
        },
    });
    /* eslint-enable */
    const [{ isDragging }, drag] = useDrag({
        item: { id: id, isInMenuList:isInMenuList, page: props.page },
        type: 'PAGE',
        end: (item, monitor) => {
            // const dropResult = monitor.getDropResult();

            // console.log(item);
            // console.log(dropResult)
            // console.log(monitor)
            //item dropped so save order
            // savePageReorder();
        },
        collect: (monitor) => {
            let isDragging = false;
            let item = monitor.getItem()
            if(item !== null){
                isDragging = item.id === id
            }
            // console.log('collect',monitor.getItem())
            return{
                isDragging: isDragging
            }
        },
    });

    const opacity = isDragging ? 0.25 : 1;
    drag(drop(ref));
    
    return (
        (
            <div ref={ref} className={props.page.parentId > 0 ? 'menu-item-sorter indent' : 'menu-item-sorter'} style={{opacity}}>{props.page.title}</div>
        )
    )
}

export default DraggablePageContainer