// Checks a user's auth status and forwards them on accordingly

// Core react and plugins
import React from 'react';

import PageTemplate from '../components/page-template';

const PageTemplateContainer = (props) => {

    return (
        <PageTemplate
        />
    );
}

export default PageTemplateContainer;
