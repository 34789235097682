import React from 'react';
import MtLogo from './../../assets/img/mt/logo.webp'
import { Link } from 'react-router-dom';


function Header(props) {

    return (
        <section className="leader short">
            <header >
                <div className="logo left">
                    <a href="/">
                        <img src={ MtLogo } alt="Morel Trust logo" />
                    </a>
                </div>
                <div className='right'>
                    {  props.permissions && 
                        <ul className="nav">
                            <li><Link to="/account">My Account</Link></li>
                            <li><Link to="/u/logout">Sign out</Link></li>
                        </ul>
                    }
                    <Link to="/apply" className="btn">Apply<span> Now</span></Link>
                </div>
            </header>

        </section>
    );
}

export default Header;
