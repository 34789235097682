import React, {useState} from 'react';

function EditableButton(props) {

    const [isOpen, setIsOpen] = useState(false);

    const toggleEditor = () => {
        setIsOpen(!isOpen);
    }

    return (
        <div className="edit-btn">
            <button className={typeof props.class !== "undefined" ? props.class : ""} onClick={() => toggleEditor()}>{props.btnTxt}</button>
            {isOpen &&
                <div className="btn-editor">
                    <div className="input text">
                        <label htmlFor={"txt-" + props.fieldName}>Button text</label>
                        <input type="text" id={"txt-" + props.fieldName} name={"txt-" + props.fieldName} defaultValue={props.btnTxt} onChange={(e) => props.updateField(props.fieldName, e.target.value)} onBlur={() => props.saveBlock()} />
                    </div>
                    <div className="input text">
                        <label htmlFor={"txt-" + props.fieldNameLnk}>Link URL</label>
                        <input type="text" id={"txt-" + props.fieldNameLnk} name={"txt-" + props.fieldNameLnk} defaultValue={props.btnLnk} onChange={(e) => props.updateField(props.fieldNameLnk, e.target.value)} onBlur={() => props.saveBlock()} />
                    </div>
                </div>
            }
        </div>
    );
}

export default EditableButton;
