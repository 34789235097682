import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import Error from '../common/forms/error';
import moment from "moment";
import Loader from '../common/loader';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Helmet } from 'react-helmet';

function ApplicationList(props){

    const statusMap = {
        "in-progress": "In progress",
        "submitted": "Submitted",
        "archived": "Archived"
    }

    const categoryMap = {
        "uk-arts": "UK Arts",
        "uk-education": "UK Social Concern",
        "international": "International"
    }
    
    return (
        <Fragment>
            <Helmet>
                <title>Manage applications :: The Morel Trust</title>
            </Helmet>

            <div className="width-3 sidebar">

                <div className="filter">
                    <h4>Filter</h4>

                    <div className="input text">
                        <label htmlFor='searchInput'>Organisation name</label>
                        <input type="text" id="searchInput" onChange={(e) => props.updateFilter("searchInput", e.target.value)} />
                    </div>

                    <div className="input checkbox">
                        <input type="checkbox" id="incInProgress" name="incInProgress" onChange={(e) => props.updateFilter("incInProgress", e.target.checked)} />
                        <label htmlFor='incInProgress'>Include applications that have not yet been submitted</label>
                    </div>

                    <div className="input checkbox">
                        <input type="checkbox" id="incArchived" name="incArchived" onChange={(e) => props.updateFilter("incArchived", e.target.checked)} />
                        <label htmlFor='incArchived'>Include archived applications</label>
                    </div>

                    <button onClick={() => props.loadApplications(true)}>Filter</button>
                </div>

                <div className="filter mtop">
                    <h4>Bulk archive</h4>
                    <p>Archive any applications that were made before:</p>

                    <DatePicker 
                        selected={ props.archiveDate} 
                        onChange={(date) => props.setArchiveDate(date)} 
                        dateFormat="dd/MM/yyyy"
                    />

                    <button onClick={() => props.bulkArchive()}>Archive</button>
                </div>
            </div>

            <div className='width-9'>
                <h1>Manage applications</h1>

                <div>

                    <Error error={props.error}/>

                    <div className="table-wrap" onScroll={props.handleScroll}>
                        <table>
                            <thead>
                                <tr>
                                    <th>Organisation</th>
                                    <th>Category</th>
                                    <th>Status</th>
                                    <th className='med-hide'>Submission date</th>
                                    <th className='big-hide'>Last updated</th>
                                    <th>&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                { typeof props.applications != "undefined" && props.applications.map( (app,i) => {
                                    return (
                                        <tr key={'tr-'+i}>
                                            <td>{(app.organisation !== null) ? app.organisation : "-"}</td>
                                            <td>{(app.category !== null) ? categoryMap[app.category] : "-"}</td>
                                            <td><span className={ "status " + app.status }>{statusMap[app.status]}</span></td>
                                            <td className='med-hide'>{ (app.submittedAt !== null) ? moment(app.submittedAt).format("DD/MM/YYYY") : "-"}</td>
                                            <td className='big-hide'>{ moment(app.UpdatedAt).format("DD/MM/YYYY") }</td>
                                            <td>
                                                {props.userPermissions.Reports ? <Link to={'/admin/applications/' + app.id} className="action-btn">View</Link> : null}
                                                {props.userPermissions.Reports && app.status !== "archived" ? <button onClick={ () => props.archiveApplication(app.id) } className="action-btn">Archive</button>: null }
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        {props.loading &&
                            <Loader />
                        }
                    </div>
                        
                </div>

            </div>
        </Fragment>
    )
}

export default ApplicationList;