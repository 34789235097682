import { CKEditor } from '@ckeditor/ckeditor5-react';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';
// import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';

import ExtractContent from '../../../utilities/extract-content';
import EditableButton from '../../admin/common/forms/editable-button';

function TrusteesAdmin(props) {

    let content = ExtractContent.block(props.block, "draft");

    return (
        <section className="trustees">

            <div className="content text-centre ">
                <div className="width-8 centred">
                    <h2 >
                        <input type="text" className="h1Input mtop-big text-centre white" placeholder="Title..." defaultValue={content.title} onInput={(e) => props.updateField("title", e.target.value)} onBlur={() => props.saveBlock()} />
                    </h2>

                    <CKEditor
                        editor={InlineEditor}
                        data={content.content ? content.content : "Enter content..."}
                        config={{
                            styles: [
                                {
                                    name: 'Center',
                                    element: 'p',
                                    styles: {
                                        'text-align': 'center'
                                    }
                                }
                            ],
                            toolbar: ['heading', '|','Bold', 'Italic', 'Link', "Unlink", "|", 'NumberedList', 'BulletedList', 'Blockquote', '|', 'undo', 'redo'],
                            heading: {
                                options: [
                                    { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                                    { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
                                    { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
                                    { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' }
                                ]
                            }
                        }}
                        onBlur={(event, editor) => {
                            const data = editor.getData();
                            props.updateField('content', data, true)
                        }}
                    />

                    <EditableButton
                        btnTxt={typeof content["action-txt-1"] !== "undefined" ? content["action-txt-1"] : "Enter link text..."}
                        fieldName="action-txt-1"
                        fieldNameLnk="action-link-1"
                        btnLnk={typeof content["action-link-1"] !== "undefined" ? content["action-link-1"] : ""}
                        updateField={props.updateField}
                        saveBlock={props.saveBlock}
                        class="centred secondary mtop"
                    />
                </div>

            </div>
        </section>
    );
}

export default TrusteesAdmin;

/*
*/