import React, { Component } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom'
import FooterContainer from '../containers/common/footer-container';
import HeaderContainer from '../containers/common/header-container';
import AdminManageContainer from '../containers/admin/users/admin-manage-container';

class AuthRouting extends Component {

    render () {
        if (this.props.rdr) {
            return <Navigate to={this.props.rdr} />
        }

        return (
            <div className="whole-admin">
                <HeaderContainer layout={ "admin" } />
                <div className="main-content">
                    <Routes>                                                
                        {/* Admin routes */}
                        <Route path='*' element={<AdminManageContainer/>}/>
                    </Routes>
                </div>

                <FooterContainer/>
            </div>
        );
    }
}

export default AuthRouting;
