// Router
import { Outlet } from "react-router-dom";

// Components
import Footer from './common/footer';

function PageTemplate(props) {

    return (
        <div className="main">
           
            <Outlet />
            <Footer />
        </div>
    );
}

export default PageTemplate;
