import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { useCookies } from 'react-cookie';

import Encryption from '../../utilities/encryption';

import Fetching from '../../utilities/fetching';
import InfoPage from '../../components/pages/info';
import Home from '../../components/pages/home';
import Loader from '../../components/admin/common/loader';
import ErrorPage from '../../components/pages/error-page';


function PagesContainer(props) {

    // Set up the state to track status 
    const [page, setPage] = useState(false);
    const [blockBarItems, setBlockBarItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [auth, setAuth] = useState(false);

    const navigate = useNavigate();

    const [authCookie] = useCookies([process.env.REACT_APP_AUTH_COOKIE]);
    let encSecret = process.env.REACT_APP_ENC_KEY;

    // URL slugs
    let { slug } = useParams();

    // Load up the article content
    const loadContent = () => {

        setLoading(true);

        // CHECK THE AUTH STATE 
        var editSlug = "";

        let authInfo = Encryption.extractHeaders(authCookie);
        if (authInfo && authInfo.permissions.Manage ) {
            editSlug = "?edit=1";
            setIsAdmin(true);
        }

        if (typeof slug == "undefined") {
            slug = "home";
        }

        // Make the request
        var reqHeaders = {
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
        };
        fetch(process.env.REACT_APP_API_BASE + '/public/pages/' + slug + editSlug, {
            method: 'get',
            headers: reqHeaders
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            // Now that the data has been retrieved, set it to state for the component to display it
            if (data.rslt === "success") {
                // Append the details to the array, don't just add them
                setPage(data.detail);

                // Scroll to the top, blurring the active element to make sure the browser doesn;t attempt to scroll to it
                document.activeElement.blur()
                window.scrollTo(0,0);

                // Set details for the CMS (if required)
                if (typeof data.detail.blocks !== 'undefined') {
                    setBlockBarItems(data.detail.blocks)
                }

            } else {
                checkRedirect(slug);
            }
            setLoading(false)

        })
        .catch(function (error) {
            checkRedirect(slug);
            // console.log('Request failed', error);
        });

    }

    // Get the auth state from the cookies - to pass down
    const loadAuthState = () => {

        // Check for a cookie
        let authInfo = Encryption.extractHeaders(authCookie);
        if (authInfo) {
            setAuth(authInfo);
        }

    }

    // Check whether there is a redirect based upon this slug 
    const checkRedirect = (slug) => {

        let slugs = {slug: "/p/" + slug};

        // Make the request
        var reqHeaders = {
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
        };
        fetch(process.env.REACT_APP_API_BASE + '/public/redirects', {
            method: 'post',
            headers: reqHeaders,
            body: JSON.stringify(slugs)
        })
        // .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            // Now that the data has been retrieved, set it to state for the component to display it
            if (data.rslt === "success") {
                // Append the details to the array, don't just add them
                navigate(data.detail);

            } else {
                setError(true);
            }
            setLoading(false)

        })
        .catch(function (error) {
            setLoading(false);
            setError(true);
            // console.log('Request failed', error);
        });

    }

    // Trigger the load on page load
    /* eslint-disable */
    useEffect(() => {
        loadContent();
        loadAuthState();
    }, []);
    useEffect(() => {
        loadContent();
    }, [slug]);
    /* eslint-enable */

    // TODO: Handle the non-existent pages (show error page)
    if (error) {
        return (
            <ErrorPage />
        );
    }

    // 
    if (typeof page.template != "undefined" && (page.template === "info" || page.template === "info-sponsor")) {

        return (
            <InfoPage
                loading={loading}
                page={page}
                error={error}
                authState={auth}
                setBlockBarItems={setBlockBarItems}
                blockBarItems={blockBarItems}
                getPage={loadContent}
                isAdmin={isAdmin}
            />
        );

    }

    if (typeof page.template != "undefined" && page.template === "home") {

        return (
            <Home
                loading={loading}
                page={page}
                error={error}
                authState={auth}
                blockBarItems={blockBarItems}
                setBlockBarItems={setBlockBarItems}
                isAdmin={isAdmin}
                getPage={loadContent}
            />
        );

    }

    return (
        <Loader />
    );
}

export default PagesContainer;
