import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';

import Encryption from '../../utilities/encryption';

import Fetching from './../../utilities/fetching';
import ExtractContent from './../../utilities/extract-content';


// Import the blocks (admn and non-admin)
import LeftImage from '../../components/blocks/public/left-img';
import LeftImageAdmin from '../../components/blocks/admin/left-img';
import RightImage from '../../components/blocks/public/right-img';
import RightImageAdmin from '../../components/blocks/admin/right-img';
import PlainTxtCentreAdmin from '../../components/blocks/admin/plain-txt-centre';
import PlainTxtCentre from '../../components/blocks/public/plain-txt-centre';
import TrusteesAdmin from '../../components/blocks/admin/trustees';
import Trustees from '../../components/blocks/public/trustees';
import LeftImageShortAdmin from '../../components/blocks/admin/left-img-short';
import LeftImageShort from '../../components/blocks/public/left-img-short';

function BlocksContainer(props) {

    // Set up the state to track status 
    const [auth, setAuth] = useState(false);
    const [blockContent, setBlockContent] = useState({});
    const [error, setError] = useState("");

    const [authCookie] = useCookies([process.env.REACT_APP_AUTH_COOKIE]);
    let encSecret = process.env.REACT_APP_ENC_KEY;

    // Get the auth state from the cookies - to pass down
    const loadAuthState = () => {

        // Check for a cookie
        let authInfo = Encryption.extractHeaders(authCookie);
        if (authInfo) {
            setAuth(authInfo);
        }

    }

    // Handle field updates from the forms
    const updateField = (fieldName, value, triggerSave = false) => {

        // Update the state
        var newContent = { ...blockContent }
        console.log(newContent);
        newContent[fieldName] = value;
        setBlockContent(newContent);
        // If this is omething that should trigger a save, then do so
        if (triggerSave) {
            saveBlock(fieldName, value);
        }

    }

    // Handle save of the block itself on blur of the fields
    const saveBlock = (fieldName, value) => {

        // Figure out the save data 
        var saveData = {
            blockId: props.block.id,
            content: blockContent
        }

        // If this is a directly triggered (e.g. CKEditor) save then don't rely on state (it updates asynchronously)
        if (typeof fieldName != "undefined" && typeof value != "undefined") {
            saveData.content[fieldName] = value;
        }

        // Run the save itself 
        fetch(process.env.REACT_APP_API_BASE + '/blocks/1', {
            method: 'post',
            headers: {
                "Content-type": "application/json",
                'api-token': auth.apiToken,
                'api-key': auth.apiKey
            },
            body: JSON.stringify(saveData)
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {
            if (typeof data.rslt == "undefined" && data.rslt !== "success") {
                setError("Sorry but there was an error saving that change. Please try again.");
                return;
            }
            setError(false);
        })
        .catch(function (error) {
            setError("Sorry but there was an error saving that change. Please try again.");
            console.log('Request failed', error);
        });

    }

    // Get the menus for the header 
    const loadContent = () => {

        // CHECK THE AUTH STATE 
        let authInfo = Encryption.extractHeaders(authCookie);
        var canEdit = false;
        if (authInfo && authInfo.permissions.Manage) {
            canEdit = true;
        }

        var content;
        if (canEdit) {
            content = ExtractContent.block(props.block, "draft");
        } else {
            content = ExtractContent.block(props.block);
        }
        setBlockContent(content);

    }

    // Trigger the load on page load
    /* eslint-disable */
    useEffect(() => {
        loadContent();
        loadAuthState();
    }, []);
    /* eslint-enable */

    // 
    if (auth && auth.permissions && auth.permissions.Manage) {

        // Set up the block options 
        const blockOpts = {
            "left-img": <LeftImageAdmin saveBlock={saveBlock} updateField={updateField} block={props.block} />,
            "right-img": <RightImageAdmin saveBlock={saveBlock} updateField={updateField} block={props.block} />,
            "plain-text-centre":<PlainTxtCentreAdmin  saveBlock={saveBlock} updateField={updateField} block={props.block} />,
            "left-img-short": <LeftImageShortAdmin saveBlock={saveBlock} updateField={updateField} block={props.block} />,
            "trustees": <TrusteesAdmin saveBlock={saveBlock} updateField={updateField} block={props.block} />
        }

        if ( typeof blockOpts[props.block.blockStyle] !== "undefined") {
            return blockOpts[props.block.blockStyle]
        }

    }

    // Set up the block options 
    const blockOptsPublic = {
        "left-img": <LeftImage block={props.block} />,
        "right-img": <RightImage block={props.block} />,
        "plain-text-centre":<PlainTxtCentre block={props.block} />,
        "left-img-short": <LeftImageShort block={props.block} />,
        "trustees" :<Trustees block={props.block} />
    }

    if (typeof blockOptsPublic[props.block.blockStyle] !== "undefined") {
        return blockOptsPublic[props.block.blockStyle]
    }

    return "";
}

export default BlocksContainer;
