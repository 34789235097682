import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';

// Components 
import Error from './../../admin/common/forms/error';


class CreateBlockInput extends Component {
    
    render() {

        if ( this.props.rdr ) {
            return <Navigate to={ this.props.rdr } />
        }

        return (
            <form className="cms-form">
                <Error {...this.props} />
                <div className="input text">
                    <label htmlFor="title">Block Label</label>
                    <input type="text" id="title" onChange={(e) => this.props.handleUpdate('title', e.target.value)} defaultValue={ this.props.content.title } />
                </div>
             
                <button onClick={ (e) => this.props.handleBlockCreate(e) }>Save</button>
            </form>
        );
    }
}

export default CreateBlockInput;
