// Core react and plugins
import React, { useState, useEffect, useRef} from 'react';

// Components
import Fetching from '../../../../utilities/fetching';
import BlockBar from '../../../../components/blocks/cms/block-bar';


// Hook itself
const BlockBarContainer = (props) => {

    const [publishClass, setPublishClass] = useState("");
    const [blockOptions, setBlockOptions] = useState("");
    const [optsStatus, setOptsStatus] = useState("");
    const [delStatus, setDelStatus] = useState("");
    const [createClass, setCreateClass] = useState("");
    const [deleteClass, setDeleteClass] = useState("");
    const [reorderStatus, setReorderStatus] = useState("");
    const [reorderClass,setReorderClass] = useState("")
    const [lbContent, setLbContent] = useState(false);

    // Get the menus for the header 
    const loadOptions = () => {

        // Run the save itself 
        fetch(process.env.REACT_APP_API_BASE + '/blocks/options', {
            method: 'get',
            headers: {
                "Content-type": "application/json",
                'api-token': props.authState.apiToken,
                'api-key': props.authState.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {
            
            if (typeof data.rslt != "undefined" && data.rslt === "success") {
                setBlockOptions(data.detail);
            }

        })
        .catch(function (error) {
            console.log('Request failed', error);
        });

    }

    /* eslint-disable */
    useEffect(() => {
        loadOptions();
    }, [])
    /* eslint-enable */

    // Handle the publication of a block
    const publishChanges = () => {

        // Figure out the save data 
        var saveData = {
            slug: props.page.slug,
        }

        setPublishClass("");

        // Run the save itself 
        fetch(process.env.REACT_APP_API_BASE + '/pages/publish', {
            method: 'post',
            headers: {
                "Content-type": "application/json",
                'api-token': props.authState.apiToken,
                'api-key': props.authState.apiKey
            },
            body: JSON.stringify(saveData)
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {
            if (typeof data.rslt == "undefined" || data.rslt !== "success") {
                setPublishClass("error");
                alert("Sorry but there was an error publishing your changes. Please try again.");
                return;
            }
            setPublishClass("success");
            setTimeout(function(){
                setPublishClass("");
            }, 5000);
        })
        .catch(function (error) {
            alert("Sorry but there was an error publishing your changes. Please try again.");
            console.log('Request failed', error);
        });

    }

    // Handle the publication of a block
    const createBlock = (e,selectedStyle,blockLabel) => {

        e.preventDefault()

        // Figure out the save data 
        var createData = {
            pageSlug: props.page.slug,
            blockStyle: selectedStyle,
            blockLabel:blockLabel
        }

        setCreateClass("");

        // Run the save itself 
        fetch(process.env.REACT_APP_API_BASE + '/blocks', {
            method: 'post',
            headers: {
                "Content-type": "application/json",
                'api-token': props.authState.apiToken,
                'api-key': props.authState.apiKey
            },
            body: JSON.stringify(createData)
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            if (typeof data.rslt == "undefined" || data.rslt !== "success") {
                setPublishClass("error");
                alert("Sorry but there was an error publishing your changes. Please try again.");
                // return;
            }

            // Append the block onto the page view
            props.getPage();

            // Update the button style temporarily
            setCreateClass("success");
            setOptsStatus("");
            setTimeout(function(){
                setCreateClass("");
            }, 5000);
        })
        .catch(function (error) {
            alert("Sorry but there was an error publishing your changes. Please try again.");
            console.log('Request failed', error);
        });

    }

    // Handle the publication of a block
    const deleteBlock = (blockId) => {

        setDeleteClass("");

        // Run the save itself 
        fetch(process.env.REACT_APP_API_BASE + '/blocks/' + blockId, {
            method: 'delete',
            headers: {
                "Content-type": "application/json",
                'api-token': props.authState.apiToken,
                'api-key': props.authState.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {
            if (typeof data.rslt == "undefined" || data.rslt !== "success") {
                setDeleteClass("error");
                alert("Sorry but there was an error deleting your block. Please try again.");
                return;
            }

            // Append the block onto the page view
            props.getPage();

            // Update the button style temporarily
            setDeleteClass("success");
            setDelStatus("");
            setTimeout(function () {
                setDeleteClass("");
            }, 5000);
        })
        .catch(function (error) {
            alert("Sorry but there was an error deleting the block. Please try again.");
            console.log('Request failed', error);
        });

    }

    const moveBlock = (dragIndex, hoverIndex) => {
 
        //prevent reorder bug triggering
        if(dragIndex === undefined  || hoverIndex === undefined){
            return;
        }

        setReorderClass("");
        
        //copy block item from parent state
        let newBlockBarItems = props.blockBarItems.map(a => ({...a}));   
        
        //insert draggable drag index at hover index
        newBlockBarItems= array_move(newBlockBarItems,hoverIndex,dragIndex)

        //update the order of the blocks in parent state
        props.setBlockBarItems(newBlockBarItems)

        
    };

    const saveBlockReorder = () => {

        setReorderClass("");

        let data = {
            "pageSlug":props.page.slug,
            "blockOrders":[]
        }

        props.blockBarItems.forEach((blockItem,i) => {
            data.blockOrders.push({
                blockId:blockItem.id,
                sortOrder:i+1
            })
        })

        // Run the save itself 
        fetch(process.env.REACT_APP_API_BASE + '/blocks/reorder', {
            method: 'POST',
            headers: {
                "Content-type": "application/json",
                'api-token': props.authState.apiToken,
                'api-key': props.authState.apiKey
            },
            body:JSON.stringify(data)
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            if(data.rslt === 'success'){
                props.getPage();
                setReorderClass("success");
                setTimeout(function(){
                    setReorderClass("");
                }, 5000);

            }
            

        })
        .catch(function (error) {
            console.log('Request failed', error);
        });

    };

    const array_move = (arr, old_index, new_index) => {
        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr; // for testing
    };

    const openCreateBlockMenu = () => {
        setOptsStatus("visible")
        setDelStatus('')
        setReorderStatus('')
    }

    const openReorderBlockMenu = () => {
        setOptsStatus('')
        setDelStatus('')
        setReorderStatus('visible')
    }

    const openDeleteBlockMenu = () => {
        setOptsStatus('')
        setDelStatus('visible')
        setReorderStatus('')
    }

    // Handle the lightbox display and hide
    const loadLightbox = (e, title, content,blockStyle) => {
        e.preventDefault();
        setLbContent({ title: title, content: content,blockStyle:blockStyle });
    }

    const updateLbContent = (title, content) => {
        setLbContent({ title: title, content: content });
    }

    const closeLightbox = (e) => {
        e.preventDefault();
        setLbContent(false);
    }

    const useOutsideAlerter = (ref) => {
        useEffect(() => {
          /**
           * Alert if clicked on outside of element
           */
          const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                setOptsStatus('')
                setDelStatus('')
                setReorderStatus('')
            }
          }
          // Bind the event listener
          document.addEventListener("mousedown", handleClickOutside);
          return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
          };
        }, [ref]);
      }

      const wrapperRef = useRef(null)
      useOutsideAlerter(wrapperRef)
    // Return the view
    return (
        <BlockBar 
            publishChanges={ publishChanges }
            publishClass={ publishClass }
            blockOptions={ blockOptions }
            optsStatus={optsStatus }
            createClass={ createClass }
            createBlock={ createBlock }
            delStatus={delStatus}
            deleteBlock={ deleteBlock }
            deleteClass={ deleteClass }
            reorderStatus={ reorderStatus }
            page={ props.page }
            moveBlock={moveBlock}
            blockBarItems={props.blockBarItems}
            saveBlockReorder={saveBlockReorder}
            reorderClass={reorderClass}
            openCreateBlockMenu={openCreateBlockMenu}
            openDeleteBlockMenu={openDeleteBlockMenu}
            openReorderBlockMenu={openReorderBlockMenu}
            wrapperRef={wrapperRef}
            loadLightbox={loadLightbox}
            updateLbContent={updateLbContent}
            closeFunc={closeLightbox}
            lightbox={lbContent}
        />
    );
}

export default BlockBarContainer;