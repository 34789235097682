function Footer() {
    return (
        <footer className="final">

            <section className="content smallprint">
                <div className="width-6">
                    <p>&copy; 2023 The Morel Trust • Registered Charity Number: 268943</p>
                    
                </div>
                <div className="width-6 right">
                    <a href="https://register-of-charities.charitycommission.gov.uk/charity-search/-/charity-details/268943" target="_blank" rel="noreferrer">The Morel Charitable Trust Charity Commission</a>
                </div>
            </section>
        </footer>
    );
}

export default Footer;
