// Core react and plugins
import React, { Component } from 'react';
import { Navigate, Link } from 'react-router-dom';
import Password from '../../common/forms/password';
import TextInput from '../../common/forms/text-input';
import Button from '../../common/forms/button';

// Components
import Error from './../../common/forms/error';
import { Helmet } from 'react-helmet';

// Component class itself
class Login extends Component {

    render() {

        if ( this.props.rdr ) {
            return <Navigate to={ this.props.rdr } />
        }

        return (

            <div className="smlr-sxn">
            <Helmet>
                <title>Sign in :: The Morel Trust</title>
            </Helmet>

                <h1>Sign in</h1>

                <Error error={ this.props.error } />

                <form>
                    <TextInput label='Email' id='email' fieldName="email" handleChange={this.props.handleAuthInfo } />
                    <Password label='Password' id='password' fieldName="password" handleChange={this.props.handleAuthInfo} />
                    <Button handleClick={ (e) => this.props.loginFunc(e) } btnTxt={ this.props.btnTxt }/>

                </form>

                <p className="sml">Forgotten your password? <Link to="/u/forgotten-password">Reset it here</Link></p>
            </div>

        );

    }
}

export default Login;