// Core react and plugins
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";

// Components
import Error from './../admin/common/forms/error';
import ValidationError from '../admin/common/forms/validation-error';
import PwMeter from '../admin/users/auth/pw-meter';

// Component class itself
const Signup = (props) => {

        return (

            <div className={ "signup" }>
                <Helmet>
                    <title>Signup :: The Morel Trust</title>
                </Helmet>

                {!props.verifyRequired ? (
                    <Fragment>
                        <h1>Create an account</h1>
                        <p>Before you can make an application, you will need to create an account using the form below.</p>
                        <Error error={ props.error } />
                    </Fragment>
                ) : (
                    <div className="verify-required">
                        <h1>Activation required</h1>
                        <Error error={ props.error } />
                        <p>Thanks for creating your account.</p> 
                        <p>Before proceeding we need to verify your email address. An email has been sent to the address you provided. Please click the link in this email to proceed.</p>
                    </div>
                )}

                { !props.verifyRequired &&

                    <form id="pwForm">

                        <div className="input text">
                            <label htmlFor="firstName">First name</label>
                            <input type="text" id="firstName" name="firstName" onChange={(e) => props.handleUpdate(e)} value={props.firstName} />
                            <ValidationError fieldName="FirstName" errors={props.valErrors} />
                        </div>

                        <div className="input text">
                            <label htmlFor="lastName">Last name</label>
                            <input type="text" id="lastName" name="lastName" onChange={(e) => props.handleUpdate(e)} value={props.lastName} />
                            <ValidationError fieldName="LastName" errors={props.valErrors} />
                        </div>

                        <div className="input text">
                            <label htmlFor="email">Email address</label>
                            <input type="email" id="email" name="email" onChange={ (e) => props.handleUpdate(e) } value={ props.email } />
                            <ValidationError fieldName="Email" errors={props.valErrors} />    
                        </div>

                        <PwMeter {...props} />
                        <div className="input text">
                            <label htmlFor="password">Create a password</label>
                            <input type="password" id="password" name="password" onChange={(e) => props.handleUpdate(e)} value={props.password} />
                            <ValidationError fieldName="Password" errors={props.valErrors} />    
                        </div>

                        <div className="input text">
                            <label htmlFor="confrm">Confirm your password</label>
                            <input type="password" id="confirm" name="confirm" onChange={(e) => props.handleUpdate(e)} value={props.confirm} />
                            <ValidationError fieldName="confirm" errors={props.valErrors} />    
                        </div>

                        <button onClick={ (e) => props.submitFunc(e) } className="btn primary">{ props.btnTxt }</button>

                        <p className="sml">Already have an account? <Link to="/login">Sign in here</Link></p>
                    </form>


                }
            </div>

        );

}

export default Signup;