// Core react and plugins
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

// Components
import Error from './../common/forms/error';
import Loader from './../common/loader';
import PageSidebar from '../common/page-sidebar';

// Component class itself
class Redirects extends Component {

    componentDidMount() {
        document.title = 'Manage redirects';
    }

    render() {

        if (this.props.loading) {
            return <Loader />;
        }

        return (

            <Fragment>
                <PageSidebar />

                <div className="width-9">
                    <Link to="#create" className="btn right" onClick={ (e) => this.props.toggleShowCreate(e) }>Create redirect</Link>
                    <h1>Manage Redirects</h1>

                    <Error error={ this.props.error } />

                    <div className='account-forms'>
                        <table className="network">
                            <thead>
                                <tr>
                                    <th>Slug to redirect from</th>
                                    <th>Slug to redirect to</th>
                                    <th>Last modified</th>
                                    <th>&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                { !this.props.redirects || this.props.redirects.length === 0 ? (
                                    <tr>
                                        <td colSpan="4"><em>Nothing to display</em></td>
                                    </tr>
                                ) : (
                                    <Fragment>
                                        {this.props.redirects.map((rdr, i) => 
                                            <Fragment key={'page-' + i}>
                                                <tr>
                                                    <td className="">
                                                        { rdr.oldSlug }
                                                    </td>
                                                    <td className="">
                                                        <input type="text" value={rdr.newSlug} onChange={(e) => this.props.updateRdr(rdr.id, e.target.value) } />
                                                    </td>
                                                    <td className="">
                                                        {moment(rdr.updatedAt ).format('HH:mm - DD/MM/YYYY') }
                                                    </td>
                                                    <td className="">
                                                        <button className="btn sml" onClick={(e) => this.props.saveRdr(e, rdr.id, false)}>{this.props.isSaving === rdr.id ? "Saving..." : "Save"}</button>
                                                        <button className="btn sml minor mrgn-lft" onClick={(e) => this.props.deleteRedirect(e, rdr.id)}>Delete</button>                                                    
                                                    </td>
                                                </tr>
                                            </Fragment>
                                        )}
                                    </Fragment>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>

                { this.props.showCreate &&
                    <div className="cover">
                        <div className="lb-content">
                            <Link to="#close" className="lb-close" onClick={(e) => this.props.toggleShowCreate(e)}>Close</Link>
                            <h3>Create redirect</h3>

                            <Error error={ this.props.error } />

                            <div className="input text">
                                <label htmlFor="oldSlug">Slug to redirect from</label>
                                <input type="text" id="oldSlug" value={this.props.newRdr.oldSlug} onChange={(e) => this.props.setNewRdr("oldSlug", e.target.value)} />
                            </div>

                            <div className="input text">
                                <label htmlFor="newSlug">Slug to redirect to</label>
                                <input type="text" id="newSlug" value={this.props.newRdr.newSlug} onChange={(e) => this.props.setNewRdr("newSlug", e.target.value)} />
                            </div>

                            <button className="btn" onClick={(e) => this.props.saveRdr(e, this.props.newRdrs, true)}>{ this.props.isSaving === "new" ? "Creating..." : "Create" }</button>
                        </div>
                    </div>
                }

            </Fragment>

        );

    }
}

export default Redirects;