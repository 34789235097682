// Core react and plugins
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import Fetching from '../../utilities/fetching';

// // State management
import { useCookies } from 'react-cookie';
import Apply from '../../components/account/apply';
import Encryption from '../../utilities/encryption';
// import AdminNav from './../../components/admin/common/admin-nav';

const ApplyContainer = (props) => {

    const { aid } = useParams();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [authCookie] = useCookies([process.env.REACT_APP_AUTH_COOKIE]);
    const [permissions, setPermissions] = useState({})
    const [questions, setQuestions] = useState([])
    const [answers, setAnswers] = useState([])
    const [app, setApp] = useState(0)
    const [valErrors, setValErrors] = useState({})
    const [error, setError] = useState(false)
    const [auth, setAuth] = useState(false);

    const getQuestions = () => {

        // If there is an application ID in the URL then get the responses as well 
        if (typeof aid !== 'undefined') {
            setApp(aid);
            getResponses(aid);
        }

        // If there is no cookie, then they are not logged in
        let authInfo = Encryption.extractHeaders(authCookie);

        setPermissions(authInfo.permissions)

        // Run a fetch to retrieve the questions for the aplication 
        fetch(process.env.REACT_APP_API_BASE + '/applications/questions', {
            method: 'get',
            headers: {
                "Content-type": "application/json",
                'api-token': authInfo.apiToken,
                'api-key': authInfo.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            // Now that the data has been retrieved, set it to state for the component to display it
            if (data.rslt === "success") {
                // Append the details to the array, don't just add them
                setQuestions(data.detail);
            }
            setLoading(false)

        })
        .catch(function (error) {
            console.log('Load questions request failed', error);
        });

    }

    const getResponses = (aid) => {

        // If there is no cookie, then they are not logged in
        let authInfo = Encryption.extractHeaders(authCookie);

        // Run a fetch to retrieve the questions for the aplication 
        fetch(process.env.REACT_APP_API_BASE + '/applications/responses/' + aid, {
            method: 'get',
            headers: {
                "Content-type": "application/json",
                'api-token': authInfo.apiToken,
                'api-key': authInfo.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            // Now that the data has been retrieved, set it to state for the component to display it
            if (data.rslt === "success") {
                // Store the responses to state for display
                setAnswers(data.detail);
            }

        })
        .catch(function (error) {
            console.log('Load questions request failed', error);
        });

    }

    // Handle responses 
    const handleResponse = (e, fieldVal, triggerSave, isFile, isRemoval) => {

        // Set up the name and value 
        let fieldName = e;
        if (typeof fieldVal === 'undefined') {
            fieldVal = e.target.value;
            fieldName = e.target.name;
        }
        fieldName = parseInt(fieldName.replace("question-", ""));

        if (typeof isFile == 'undefined') isFile = false;
        var newAnswers = [...answers];
        var hasUpdated = false;
        var updatedAns = false;
        for (var i in newAnswers) {
            if (newAnswers[i].questionId === fieldName) {
                // For files add the file to the array of files
                if (isFile) {
                    var ansObj = JSON.parse(newAnswers[i].answer);
                    if (isRemoval) {
                        ansObj = ansObj.filter(function (file) {
                            return file.id !== fieldVal;
                        });
                    } else {
                        ansObj.push(fieldVal);
                    }
                    newAnswers[i].answer = JSON.stringify(ansObj);
                } else {
                    newAnswers[i].answer = fieldVal;
                }
                updatedAns = newAnswers[i].answer;
                setAnswers(newAnswers);
                if (!triggerSave) {
                    return;
                }
                hasUpdated = true;
                break;
            }
        }

        if (!hasUpdated) {
            let newAns = {
                questionId: fieldName,
                answer: (isFile) ? JSON.stringify([fieldVal]) : fieldVal
            };
            updatedAns = newAns.answer;
            newAnswers.push(newAns);
            setAnswers(newAnswers);
        }
    
        if (triggerSave) {
            saveResponse(fieldName, updatedAns);
        }
    }

    const saveResponse = (qid, ans) => {

        // If there is no cookie, then they are not logged in
        let authInfo = Encryption.extractHeaders(authCookie);

        setPermissions(authInfo.permissions)
        
        qid = (isNaN(qid)) ? parseInt(qid.replace("question-", "")) : qid;
        if (typeof ans === 'undefined') {
            ans = "";
            for (var i in answers) {
                if (answers[i].questionId === qid) {
                    ans = answers[i].answer;
                    break;
                }
            }
        }

        // Stringify array answers
        if (typeof ans === 'object') {
            ans = JSON.stringify(ans);
        }

        // Set up the response object for submission
        let response = {
            answer: ans,
            questionId: qid,
            applicationId: parseInt(app)
        }

        // Run a fetch to retrieve the questions for the aplication 
        fetch(process.env.REACT_APP_API_BASE + '/applications/response', {
            method: 'post',
            headers: {
                "Content-type": "application/json",
                'api-token': authInfo.apiToken,
                'api-key': authInfo.apiKey
            },
            body: JSON.stringify(response)
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            // Now that the data has been retrieved, set it to state for the component to display it
            if (data.rslt === "success") {
                // Append the details to the array, don't just add them
                // Clear val errors
                setApp(data.detail)
                var newErrors = { ...valErrors };
                if (typeof newErrors["question-" + qid] !== 'undefined') {
                    delete newErrors["question-" + qid];
                    setValErrors(newErrors);
                }
                return;
            }

            // Set a validation error
            var nuErrors = {...valErrors};
            nuErrors["question-" + qid] = [data.msg];
            setValErrors(nuErrors);

            // If an application has been created then track that 
            if (data.detail !== 0 && data.detail !== null) {
                setApp(data.detail);
            }

        })
        .catch(function (error) {
            console.log('Load questions request failed', error);
        });

    }

    const submit = () => {

        // If there is no cookie, then they are not logged in
        let authInfo = Encryption.extractHeaders(authCookie);

        // Run a fetch to retrieve the questions for the aplication 
        fetch(process.env.REACT_APP_API_BASE + '/applications/submit/' + app, {
            method: 'get',
            headers: {
                "Content-type": "application/json",
                'api-token': authInfo.apiToken,
                'api-key': authInfo.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            // Now that the data has been retrieved, set it to state for the component to display it
            scrollToTop();
            if (data.rslt === "success") {
                // Store the responses to state for display
                setError({ msg: data.msg, style: "grn" });
                return;
            }

            setError({ msg: data.msg, style: "red"});
            if (data.detail !== 0 && data.detail !== null) {
                var newErrors = {};
                for (var j in data.detail) {
                    newErrors[data.detail[j].FieldID] = [data.detail[j].Msg];
                }
                console.log(newErrors);
                setValErrors(newErrors);
            }

        })
        .catch(function (error) {
            console.log('Load questions request failed', error);
        });

    }

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    // Get the auth state from the cookies - to pass down
    const loadAuthState = () => {

        // Check for a cookie
        let authInfo = Encryption.extractHeaders(authCookie);
        if (authInfo) {
            setAuth(authInfo);
            getQuestions();
            return;
        }

        // If there is no cookie, then they are not logged in - so rediretc to the signup page
        navigate("/u/signup")

    }

    /* eslint-disable */
    useEffect(() => {
        loadAuthState()
    }, [])
    /* eslint-enable */

    return (
        <Apply
            questions={questions}
            answers={answers}
            loading={loading}
            permissions={permissions}
            handleResponse={handleResponse}
            saveResponse={saveResponse}
            valErrors={valErrors}
            error={error}
            submit={submit}
            authState={auth}
        />
    );
}

export default ApplyContainer;