// Core react and plugins
import React, {  useState,useEffect } from 'react';

import Encryption from '../../utilities/encryption';

// // State management
import { useCookies } from 'react-cookie';
import Header from '../../components/common/header';
// import AdminNav from './../../components/admin/common/admin-nav';

const PublicHeaderContainer = (props) => {

    const [authCookie] = useCookies([process.env.REACT_APP_AUTH_COOKIE]);
    const [permissions,setPermissions] = useState({})
    
    const getUserPermissions = () =>{

        // If there is no cookie, then they are not logged in
        let authInfo = Encryption.extractHeaders(authCookie);
        if (!authInfo) {
            // Not logged in so everything is cool
            setPermissions(false);
            return;
        }

        setPermissions(authInfo.permissions)
    }

    /* eslint-disable */
    useEffect(() => {
        getUserPermissions()
    },[])
   /* eslint-enable */

    return (
        <Header 
            permissions={permissions}
        />
    );
}

export default PublicHeaderContainer;