import React from 'react';
import ValidationError from '../../admin/common/forms/validation-error';

const TextInput = (props) => {

    return (
        <div className="input text">
            <label htmlFor={props.name}>{props.label}</label>
            <input type="text" name={props.name} id={props.name} defaultValue={props.value} onChange={ (e) => props.handleResponse(props.name, e.target.value, false)} onBlur={ () => props.saveResponse(props.name) } />
            <ValidationError errors={props.valErrors} fieldName={props.name} />
        </div>
    );
}

export default TextInput;
