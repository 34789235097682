import React from 'react';
import { Link } from 'react-router-dom';

function ReportsSidebar(props){

    return (
        <div className="width-3 sidebar">
            <nav>
                <ul>
                    <li><Link to="/admin/signups">Newsletter signups</Link></li>
                    <li><Link to="/admin/donations">Donations reports</Link></li>
                </ul>
            </nav>
        </div>
    )
}

export default ReportsSidebar;