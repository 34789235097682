import React from 'react';
import { Link } from 'react-router-dom';

function PageSidebar(props){

    return (
        <div className="width-3 sidebar">
            <div className="filter">
                <nav>
                    <ul>
                        <li><Link to="/admin/pages">Pages</Link></li>
                        
                    </ul>
                </nav>
            </div>
        </div>
    )
}

export default PageSidebar

/*
<li><Link to="/admin/slideshow">Homepage slideshow</Link></li>
<li><Link to="/admin/wording">Custom wording</Link></li>
<li><Link to="/admin/redirects">Page redirects</Link></li>
<li><Link to="/admin/instagram">Manage instagram account</Link></li>
                    <li><Link to="/admin/menus">Menus</Link></li>

*/