import React, {Fragment, useState} from 'react';
import DisplayImageContainer from '../../../containers/images/display-image-container';
import Error from '../../admin/common/forms/error';

function FileInput(props) {

    const [imageName, setImageName] = useState(false);

    return (
        <div className="input files">
            <div className="pseudo-label" dangerouslySetInnerHTML={{ __html: props.label }}></div>
            <div className="field-box">
                <div className="uploaded">
                    { props.files.length > 0 && 
                        <ul>
                            { props.files.map( (file, i) => 
                                <li key={ "file-" + i }>{ file.Filename } <button className="remove" onClick={(e) => props.deleteFile(e, file.ID)}>Delete</button></li>    
                            )}
                        </ul>
                    }
                </div>

                <Error error={ props.error } />

                <div className={ props.imageId ? "file-input populated" : "file-input"}>
                    { props.imageId ? (
                        <Fragment>
                            <DisplayImageContainer imageId={ props.imageId } size="tmb" class="sel-thumb" returnName={ setImageName } />
                            { imageName ? (
                                <span>{props.btnTxt}</span>
                            ) : (
                                <span>{ props.btnTxt }</span>
                            )}
                        </Fragment>
                    ) : (
                        <span>{ props.btnTxt }</span>
                    )}
                    <input type="file" name="file" id="imgFile" className="img-uploader" multiple={false} onChange={(e) => props.uploadFile(e)} />
                </div>
            </div>
        </div>
    );
}

export default FileInput;

