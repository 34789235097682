// Core react and plugins
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

// Components
import Error from './../common/forms/error';
import Loader from './../common/loader';
import PageSidebar from '../common/page-sidebar';

// Component class itself
class Wording extends Component {

    componentDidMount() {
        document.title = 'Manage wording';
    }

    render() {

        if (this.props.loading) {
            return <Loader />;
        }

        return (

            <Fragment>
                <PageSidebar />

                <div className="width-9">
                    <Link to="#create" className="btn right" onClick={ (e) => this.props.toggleShowCreate(e) }>Create wording</Link>
                    <h1>Manage Custom Wording</h1>

                    <Error error={ this.props.error } />

                    <div className='account-forms'>
                        <table className="network">
                            <thead>
                                <tr>
                                    <th>Wording name</th>
                                    <th>Wording</th>
                                    <th>Last modified</th>
                                    <th>&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                { !this.props.wording || this.props.wording.length === 0 ? (
                                    <tr>
                                        <td colSpan="4"><em>Nothing to display</em></td>
                                    </tr>
                                ) : (
                                    <Fragment>
                                        {this.props.wording.map((word, i) => 
                                            <Fragment key={'page-' + i}>
                                                <tr>
                                                    <td className="">
                                                        { word.wordName }
                                                    </td>
                                                    <td className="">
                                                        <input type="text" value={ word.words } onChange={ (e) => this.props.updateWord(word.wordName, e.target.value) } />
                                                    </td>
                                                    <td className="">
                                                        {moment(word.updatedAt ).format('HH:mm - DD/MM/YYYY') }
                                                    </td>
                                                    <td className="">
                                                        <button className="btn sml" onClick={(e) => this.props.saveWording(e, word.wordName, false)}>{ this.props.isSaving === word.wordName ? "Saving..." : "Save" }</button>                                                    
                                                    </td>
                                                </tr>
                                            </Fragment>
                                        )}
                                    </Fragment>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>

                { this.props.showCreate &&
                    <div className="cover">
                        <div className="lb-content">
                            <Link to="#close" className="lb-close" onClick={(e) => this.props.toggleShowCreate(e)}>Close</Link>
                            <h3>Create wording</h3>

                            <Error error={ this.props.error } />

                            <div className="input text">
                                <label htmlFor="wordName">Wording name</label>
                                <input type="text" id="wordName" value={this.props.newWords.wordName} onChange={(e) => this.props.setNewWords("wordName", e.target.value)} />
                            </div>

                            <div className="input text">
                                <label htmlFor="words">Wording</label>
                                <input type="text" id="words" value={this.props.newWords.words} onChange={(e) => this.props.setNewWords("words", e.target.value)} />
                            </div>

                            <button className="btn" onClick={(e) => this.props.saveWording(e, this.props.newWords.wordName, true)}>{ this.props.isSaving === this.props.newWordName ? "Creating..." : "Create" }</button>
                        </div>
                    </div>
                }

            </Fragment>

        );

    }
}

export default Wording;