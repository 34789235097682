// Core react and plugins
import React, { useState, useEffect } from 'react';

import Fetching from '../../utilities/fetching';

// // State management
import { useCookies } from 'react-cookie';
import Encryption from '../../utilities/encryption';
import Account from '../../components/account/account';

const AccountContainer = (props) => {

    const [progressing, setProgressing] = useState([]); // eslint-disable-line
    const [submitted, setSubmitted] = useState([]); // eslint-disable-line
    const [loading, setLoading] = useState(false);
    const [authCookie] = useCookies([process.env.REACT_APP_AUTH_COOKIE]);
    const [auth, setAuth] = useState(false);

    const getApplications = () => {

        // If there is no cookie, then they are not logged in
        let authInfo = Encryption.extractHeaders(authCookie);

        // Run a fetch to retrieve the questions for the aplication 
        fetch(process.env.REACT_APP_API_BASE + '/applications', {
            method: 'get',
            headers: {
                "Content-type": "application/json",
                'api-token': authInfo.apiToken,
                'api-key': authInfo.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            // Now that the data has been retrieved, set it to state for the component to display it
            setLoading(false)
            if (data.rslt === "success") {
                // Append the details to the array, don't just add them
                setProgressing(data.detail.progressing);
                setSubmitted(data.detail.submitted);
            }

        })
        .catch(function (error) {
            console.log('Load questions request failed', error);
        });

    }

    // Get the auth state from the cookies - to pass down
    const loadAuthState = () => {

        // Check for a cookie
        let authInfo = Encryption.extractHeaders(authCookie);
        if (authInfo) {
            setAuth(authInfo);
        }

    }

    /* eslint-disable */
    useEffect(() => {
        getApplications()
        loadAuthState()
    }, [])
    /* eslint-enable */

    return (
        <Account
            progressing={progressing}
            submitted={submitted}
            loading={loading}
            authState={auth}
        />
    );
}

export default AccountContainer;