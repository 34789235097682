// Core react and plugins
import React, {  useState,useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Header from './../../components/admin/common/admin-header';

import Encryption from '../../utilities/encryption';

// // State management
import { useCookies } from 'react-cookie';
// import AdminNav from './../../components/admin/common/admin-nav';

const HeaderContainer = (props) => {
    let location = useLocation();

    const [rdr, setRdr] = useState(false); // eslint-disable-line
    const [hide, setHide] = useState(false);
    const [authCookie] = useCookies([process.env.REACT_APP_AUTH_COOKIE]);
    const [permissions,setPermissions] = useState({})
    
    const getUserPermissions = () =>{

        // If there is no cookie, then they are not logged in
        let authInfo = Encryption.extractHeaders(authCookie);
        if (!authInfo || !authInfo.permissions.Manage) {
            // Not logged in so everything is cool
            setRdr("/");
            return;
        }

        setPermissions(authInfo.permissions)
    }

    useEffect(() => {
        if (location.pathname.indexOf("practice") > -1 || location.pathname.indexOf("assessment") > -1) {
            setHide(true);
        } else {
            setHide(false);
        }
        getUserPermissions()
    }, [location])

    /* eslint-disable */
    useEffect(() => {
        getUserPermissions()
    },[])
   /* eslint-enable */

    // if (typeof props.layout != "undefined" && props.layout === "admin") {
    //     return (
    //         <AdminNav permissions={ permissions } />
    //     );
    // }

    return (
        <Header 
            permissions={permissions}
            hide={ hide }
        />
    );
}

export default HeaderContainer;