import CryptoJS from 'crypto-js';

// Check the status of a fetch request
const Encryption = {
    extractHeaders: function (cookie) {
        // Check the cookie is valid
        if (typeof cookie[process.env.REACT_APP_AUTH_COOKIE] === "undefined") {
            console.log("No cookie found");
            return false;
        }

        // Check that the auth stuff is set up in the cookie
        if (typeof cookie[process.env.REACT_APP_AUTH_COOKIE].u === "undefined") {
            return false;
        }

        // Decrypt the cookie contents 
        let encSecret = process.env.REACT_APP_ENC_KEY;

        var reformData = CryptoJS.AES.decrypt(cookie[process.env.REACT_APP_AUTH_COOKIE].u, encSecret)
        reformData = JSON.parse(reformData.toString(CryptoJS.enc.Utf8));

        return reformData;

    }
}

export default Encryption;
