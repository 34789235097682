// Core react and plugins
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Fetching from '../../utilities/fetching';

// // State management
import { useCookies } from 'react-cookie';

import Encryption from '../../utilities/encryption';
import View from '../../components/account/view';

const ViewContainer = (props) => {

    const { aid } = useParams();

    const [loading, setLoading] = useState(false);
    const [authCookie] = useCookies([process.env.REACT_APP_AUTH_COOKIE]);
    const [questions, setQuestions] = useState([])
    const [answers, setAnswers] = useState([])
    const [auth, setAuth] = useState(false);

    const getQuestions = () => {

        // If there is an application ID in the URL then get the responses as well 
        if (typeof aid !== 'undefined') {
            getResponses(aid);
        }

        // If there is no cookie, then they are not logged in
        let authInfo = Encryption.extractHeaders(authCookie);

        // Run a fetch to retrieve the questions for the aplication 
        fetch(process.env.REACT_APP_API_BASE + '/applications/questions', {
            method: 'get',
            headers: {
                "Content-type": "application/json",
                'api-token': authInfo.apiToken,
                'api-key': authInfo.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            // Now that the data has been retrieved, set it to state for the component to display it
            if (data.rslt === "success") {
                // Append the details to the array, don't just add them
                setQuestions(data.detail);
            }
            setLoading(false)

        })
        .catch(function (error) {
            console.log('Load questions request failed', error);
        });

    }

    const getResponses = (aid) => {

        // If there is no cookie, then they are not logged in
        let authInfo = Encryption.extractHeaders(authCookie);

        // Run a fetch to retrieve the questions for the aplication 
        fetch(process.env.REACT_APP_API_BASE + '/applications/responses/' + aid, {
            method: 'get',
            headers: {
                "Content-type": "application/json",
                'api-token': authInfo.apiToken,
                'api-key': authInfo.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            // Now that the data has been retrieved, set it to state for the component to display it
            if (data.rslt === "success") {
                // Store the responses to state for display
                setAnswers(data.detail);
            }

        })
        .catch(function (error) {
            console.log('Load questions request failed', error);
        });

    }

    // Get the auth state from the cookies - to pass down
    const loadAuthState = () => {

        // Check for a cookie
        let authInfo = Encryption.extractHeaders(authCookie);
        if (authInfo) {
            setAuth(authInfo);
        }

    }

    /* eslint-disable */
    useEffect(() => {
        getQuestions()
        loadAuthState()
    }, [])
    /* eslint-enable */

    return (
        <View
            questions={questions}
            answers={answers}
            loading={loading}
            authState={auth}
        />
    );
}

export default ViewContainer;