import React, { Fragment } from 'react';

function DisplayFile(props) {

    return (
        <Fragment>
            { typeof props.files !== "undefined" && props.files !== null && props.files.length > 0 &&
                <div className="downloads">
                    <h4>File downloads</h4>
                    <ul className="files-list">
                        {props.files.map((file, index) => 
                            <li key={"f-" + index}>
                                <a href={process.env.REACT_APP_S3_BASE + "/" + file.FilePath + file.Filename } target="_blank" rel="noreferrer">{file.Filename}</a>
                            </li>
                        )}
                    </ul>
                </div>
            }
        </Fragment>
    );
}

export default DisplayFile;
