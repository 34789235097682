import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';
import ValidationError from '../../admin/common/forms/validation-error';

const WysiwygInput = (props) => {

    return (
        <div className="input text">
            <label htmlFor={props.name}>{props.label}</label>
            <CKEditor
                editor={InlineEditor}
                data={ props.value ? props.value : ""}
                config={{
                    toolbar: ['heading', '|', 'Bold', 'Italic', 'Link', "Unlink", "|", 'NumberedList', 'BulletedList', 'Blockquote', '|', 'undo', 'redo'],
                    heading: {
                        options: [
                            { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                            { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
                            { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
                            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' }
                        ]
                    }
                }}
                onBlur={(event, editor) => {
                    const data = editor.getData();
                    props.handleResponse(props.name, data, true)
                }}
            />
            <ValidationError errors={props.valErrors} fieldName={props.name} />
        </div>
    );
}

export default WysiwygInput;
