function AdminFooter(props){

    return (
        <footer className='admin-footer'>
            <div className="centred">          
                <p>Content and design copyright &copy; 2022 Aline Digital.</p>
            </div>
        </footer>
    )
}

export default AdminFooter