// Core react and plugins
import React from 'react';
import AdminFooter from '../../components/admin/common/admin-footer';

const FooterContainer = (props) => {
    return (
        <AdminFooter />
    );
}

export default FooterContainer;