// Core react and plugins
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

// Components
import Error from './../common/forms/error';
import Loader from './../common/loader';
import ReportsSidebar from '../common/reports-sidebar';

// Component class itself
const Signups = (props) => {

    if (props.loading) {
        return <Loader />;
    }

    return (

        <Fragment>
            <ReportsSidebar />

            <div className="width-9 scroll-table" onScroll={props.handleScroll}>
                <Link to="#export" className="btn right" onClick={ (e) => props.exportSignups(e) }>Export CSV</Link>
                <h1>Manage Signups</h1>

                <Error error={ props.error } />

                <div className='account-forms'>
                    <table className="network">
                        <thead>
                            <tr>
                                <th>Email address</th>
                                <th>Data protection permissons granted</th>
                                <th>Created</th>
                                <th>Last modified</th>
                                <th>&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!props.signups || props.signups.length === 0 ? (
                                <tr>
                                    <td colSpan="5"><em>Nothing to display</em></td>
                                </tr>
                            ) : (
                                <Fragment>
                                    {props.signups.map((signup, i) => 
                                        <Fragment key={'su-' + i}>
                                            <tr>
                                                <td className="">
                                                    {signup.email }
                                                </td>
                                                <td className="">
                                                    {signup.PermissionsGranted ? "Yes" : "No" }
                                                </td>
                                                <td className="">
                                                    {moment(signup.CreatedAt ).format('HH:mm - DD/MM/YYYY') }
                                                </td>
                                                <td className="">
                                                    {moment(signup.UpdatedAt ).format('HH:mm - DD/MM/YYYY') }
                                                </td>
                                                <td className="">
                                                    {signup.PermissionsGranted ? (
                                                        <button className="action-btn" onClick={(e) => props.togglePermissions(e, signup.id)}>Revoke permissions</button>                                                    
                                                    ) : (
                                                        <button className="action-btn" onClick={(e) => props.togglePermissions(e, signup.id)}>Grant permissions</button>                                                    
                                                    )}
                                                </td>
                                            </tr>
                                        </Fragment>
                                    )}
                                </Fragment>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>

        </Fragment>

    );

}

export default Signups;