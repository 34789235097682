import React from 'react';

function DisplayImage(props) {

    if (props.isBgImage) {

        return (
            <div className={typeof props.class !== "undefined" ? props.class : "" } style={{ backgroundImage: "url(" + props.image.imgUrl + ")" }}></div>
        );

    }

    return (
        <img src={props.image.imgUrl} alt={ typeof props.alt !== "undefined" ? props.alt : ""} />
    );
}

export default DisplayImage;

