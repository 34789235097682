// Core react and plugins
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

// Components
import Error from './../common/forms/error';
import Loader from './../common/loader';
import PageSidebar from '../common/page-sidebar';

// Component class itself
class PageList extends Component {

    componentDidMount() {
        document.title = 'Manage pages';
    }

    render() {

        if (this.props.loading) {
            return <Loader />;
        }

        return (

            <Fragment>
                <PageSidebar />

                <div className="width-9">
                    <Link to="/admin/pages/create" className="btn right">Create Page</Link>
                    <h1>Manage Pages</h1>

                    <Error error={ this.props.error } />

                    <div className='account-forms'>
                        <table className="network">
                            <thead>
                                <tr>
                                    <th>Page title</th>
                                    <th>Page slug</th>
                                    <th>Created</th>
                                    <th>Last modified</th>
                                    <th>&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                { !this.props.pages || this.props.pages.length === 0 ? (
                                    <tr>
                                        <td colSpan="5"><em>Nothing to display</em></td>
                                    </tr>
                                ) : (
                                    <Fragment>
                                        {this.props.pages.map((page, i) => 
                                            <Fragment key={'page-' + i}>
                                                <tr>
                                                    <td className="">
                                                        <Link to={"/p" + page.slug}>{page.title}</Link>
                                                    </td>
                                                    <td className="">
                                                        { page.slug }
                                                    </td>
                                                    <td className="">
                                                        {moment(page.created ).format('HH:mm - DD/MM/YYYY') }
                                                    </td>
                                                    <td className="">
                                                        {moment(page.modified ).format('HH:mm - DD/MM/YYYY') }
                                                    </td>
                                                    <td className="">
                                                        <Link to={"/admin/pages"+page.slug}>Edit</Link>
                                                        <button className="action-btn" onClick={(e) => this.props.deletePage(e, page.slug)}>Delete</button>                                                    
                                                    </td>
                                                </tr>
                                            </Fragment>
                                        )}
                                    </Fragment>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>

            </Fragment>

        );

    }
}

export default PageList;