
// Check the status of a fetch request
const Fetching = {
  statusCheck: function (response) {
    if ((response.status >= 200 && response.status < 300 ) || (response.status === 0 && response.type === "opaque") ) {
      return Promise.resolve(response)
		} else if (response.status === 422) {
      return Promise.resolve(response)
    } else if (response.status === 403) {
      window.location.href = '/u/logout';
			return Promise.reject("auth");
    }  else if (response.status === 401){
      window.location.href = '/u/logout';
    } else {
      return Promise.reject(response)
    }
  },
  awsS3StatusCheck: function (response) {
    if (response.status >= 200 && response.status < 300 ) {
      return Promise.resolve(response)
    } else {
      return Promise.reject(response)
    }
  },
  adminStatusCheck: function (response) {

    if ((response.status >= 200 && response.status < 300) || (response.status === 0 && response.type === "opaque")) {
      return Promise.resolve(response)
		} else if (response.status === 422) {
      return Promise.resolve(response)
    } else if (response.status === 403) {
      window.location.href = '/u/logout';
			return Promise.reject("auth");
    }  else if (response.status === 401){
      window.location.href = '/u/logout';
      return Promise.reject("unauthorised");
    } else {
      return Promise.reject(response)
    }
  },

  // Extract JSOn from fetch stream
  jsonExtract: function (response) {
    return response.json()
  },

  processErrors: function (errors) {
    let newErrors = {};
    errors.forEach((error) => {
      if (typeof newErrors[error.field] === 'undefined') {
        newErrors[error.field] = [];
      }
      // Push the new error into the array
      newErrors[error.field].push(error.error);
    });
    return newErrors;
  }

}

export default Fetching;
