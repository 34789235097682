import React, { useRef } from 'react';

// Components
import { useDrag, useDrop } from 'react-dnd';


// Hook itself
const DraggableBlockContainer = (props) => {

    const { id, index, moveBlock,saveBlockReorder } = props;

    const ref = useRef(null);
    const [{ handlerId }, drop] = useDrop({
        accept: 'BLOCK',
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            // Time to actually perform the action

            moveBlock(dragIndex, hoverIndex);
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        },
    });
    const [{ isDragging }, drag] = useDrag({
        item: { id: id },
        type: 'BLOCK',
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult();

            console.log(item);
            console.log(dropResult)
            console.log(monitor)
            //item dropped so save order
            saveBlockReorder();
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });
    const opacity = isDragging ? 0 : 1;
    drag(drop(ref));

    let blockText = props.opt.style+" (Posn: "+props.opt.posn+")";

    if( typeof props.opt.label  !== 'undefined' && props.opt.label !== ""){
        blockText = props.opt.label + " (" +props.opt.style +")"
    }
    
    return (
        <li ref={ref} style={{opacity }} data-handler-id={handlerId}>
            <button className={"block-option mover"}>{blockText}</button>
        </li>
    )
}

export default DraggableBlockContainer