// TODO: Handling CSRF attacks?!?!
// Check current auth status
// User will have been routed elsewhere if they have already logged in. So there should be no need to do an auth check on this component

// Core react and plugins
import React, { useState } from 'react';

// Utilities
import Fetching from '../../../../utilities/fetching';

// State management
import useFlash from '../../../../store/flash';

// View components
import ForgottenPw from './../../../../components/admin/users/auth/forgotten-pw';

const ForgottenPwContainer = () => {

    // State management
    const [rdr, setRdr] = useState(false);
    const [email, setEmail] = useState(false);
    const [btnTxt, setBtnTxt] = useState("Request a password reset");
    const [loading, setLoading] = useState(false);
    const [flashState, flashActions] = useFlash(); // eslint-disable-line
    const [error, setError] = useState(false);

    // Update the auth info local state (based upon entered info)
    const handleUpdate = ( _, val ) => {
        setEmail(val);
    }

    // Submit function
    // Submit the entered username
    const submit = (e) => {

        e.preventDefault();
        setBtnTxt("Requesting a password reset...");

        setLoading(true);

        // Submit the login request
        fetch(process.env.REACT_APP_API_BASE + '/users/forgotten-password', {
            method: 'post',
            headers: {
                "Content-type": "application/json; charset=utf-8"
            },
            body: JSON.stringify({email:email})
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            setLoading(false);
            if (data) {
                flashActions.set({ msg: "We have sent you an email with details about how to reset your password.", style: "grn" });
                setRdr('/login');
                return;
            }
            setError({ msg: "Sorry but there was a problem requesting a password reset. Please check your details and try again.", style: "red" });
            setBtnTxt("Request a password reset");

        })
        .catch(function (error) {
            setLoading(false);
            setError({ msg: "Sorry but there was a problem requesting a password reset. Please check your details and try again.", style: "red" });
            setBtnTxt("Request a password reset");
        });

    }

    return (
        <ForgottenPw
            submitFunc={ submit }
            handleUpdate={ handleUpdate }
            error={ error }
            rdr={rdr}
            loading={ loading }
            btnTxt={ btnTxt }
        />
    );
}

export default ForgottenPwContainer;
