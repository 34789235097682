import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

function Images(props) {

    return (
        <div className="cover">
            <div className="image-manager">
                <Link to="#close" className="close-lnk" onClick={ (e) => props.toggleImageManager(e) }>Close</Link>
                <div className="folders">
                    <h5 onClick={ (e) => props.selectFolder(e, {ID: 0, ParentID: 0, Name: "Image manager"}) }>Folders</h5>
                    <ul>
                        { props.folders.map( (folder, i) => 
                            <li key={'folder-' + i}>
                                <Link to="#folder" onClick={ (e) => props.selectFolder(e, folder) }>
                                    {folder.Name}
                                </Link>
                                { typeof folder.Children !== "undefined" && folder.Children !== null && folder.Children.length > 0 && 
                                    <ul className={ ((props.parent !== 0 && props.parent === folder.ID) || (props.folder && props.folder.ID === folder.ID)) ? "open" : "" }>
                                        { folder.Children.map((child, j) =>
                                            <li key={'child-' + j}>
                                                <Link to="#folder" onClick={(e) => props.selectFolder(e, child)}>
                                                    {child.Name}
                                                </Link>
                                            </li>
                                        )}
                                    </ul>
                                }
                            </li>    
                        )}
                    </ul>

                    { (!props.folder || props.folder.ParentID === 0) && 
                        <form className="create-folder">
                            <div className="input text">
                                <label htmlFor="folder-name">Create folder</label>
                                <input type="text" id="folder-name" placeholder="Folder name" value={ props.createFolderName } onChange={ (e) => props.setCreateFolderName(e.target.value) } />
                            </div>
                            <button onClick={ (e) => props.createFolder(e) }>Create</button>
                        </form>
                    }
                </div>

                <div className="images">
                    <h3>{ props.folder ? props.folder.Name : "Image manager" }</h3>

                    <div className="img-list">
                        {props.images !== null && props.images.length > 0 &&
                            <Fragment>
                                { props.images.map( (image, k) =>
                                    <div key={'img-' + k} title={image.possibleSizes.indexOf(props.requiredSize) > -1 ? "Double click to select" : "Image is too small to use in this location"} className={image.possibleSizes.indexOf(props.requiredSize) > -1 ? "img" : "img faded"}>
                                        {image.possibleSizes.indexOf(props.requiredSize) > -1 && 
                                            <Link to="#select" className="img-select" onDoubleClick={(e) => props.selectImage(e, image)}>Select</Link>
                                        }
                                        <div className="img-thumb" style={{ backgroundImage: 'url(' + image.imgUrl + ')' }}>&nbsp;</div>
                                        <div className="img-name">{ image.filename }</div>
                                        <Link to="#delete" className="img-delete" onClick={(e) => props.deleteImage(e, image.id) }>Delete</Link>
                                    </div>
                                )}
                            </Fragment>
                        }
                    </div>

                    <div className="img-upload">
                        <label htmlFor='imgFile'>{ props.btnTxt }</label>
                        <input type="file" name="file" id="imgFile" className="img-uploader" multiple={false} onChange={ (e) => props.uploadImage(e)} />
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Images;

