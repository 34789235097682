import React, { Fragment } from 'react';
import { Link } from 'react-router-dom'

import Loader from '../admin/common/loader';
import Error from '../admin/common/forms/error';
import BlockBarContainer from '../../containers/admin/blocks/cms/block-bar-container';
import Permissions from '../../utilities/permissions';
import MtLogo from './../../assets/img/mt/logo.webp';
import DownloadsContainer from '../../containers/account/fields/downloads-container';
import { Helmet } from 'react-helmet';

const View = (props) => {

    let answers = {};
    for (var i in props.answers) {
        answers["question-" + props.answers[i].questionId] = props.answers[i].answer;
    }

    const categoryMap = {
        "uk-arts": "UK Arts",
        "uk-social": "UK Social Concern",
        "international": "International"
    }

    return (
        <div className="sided">

            <Helmet>
                <title>View my application :: The Morel Trust</title>
            </Helmet>

            {props.authState && Permissions.actionCheck("admin", "all", props.authState.permissions) &&
                <BlockBarContainer {...props} />
            }

            <div className='logo-sxn'>
                <img src={MtLogo} alt="Morel Trust logo" />

                <nav>
                    <ul>
                        <li><Link to="/account">My Account</Link></li>
                        <li><Link to="/apply">Create Application</Link></li>
                        <li className='btm-lnk'><Link to="/u/logout">Sign out</Link></li>
                    </ul>
                </nav>
            </div>
            <div className="core">
                <div className="rght-content">

                    <h1>My application</h1>
                    <Error error={props.error} />

                    { props.loading ? (
                        <Loader />
                    ) : (
                        <Fragment>
                            { props.questions.map((question, index) => 
                                <div key={"1-" + index} className="question-display">
                                    {question.questionType === "files" ? (
                                        <div dangerouslySetInnerHTML={{ __html: question.question }}></div>
                                    ) : (
                                        <h5>{question.question}</h5>
                                    )}

                                    { (question.questionType === "text" || question.questionType === "email") && 
                                        <div className='answer-display'>
                                            { (typeof answers["question-" + question.id] !== "undefined") ? answers["question-" + question.id] : "" }
                                        </div>
                                    }

                                    { (question.questionType === "select") && 
                                        <div className='answer-display'>
                                            {(typeof answers["question-" + question.id] !== "undefined" && typeof categoryMap[answers["question-" + question.id]] != "undefined") ? categoryMap[answers["question-" + question.id]] : "" }
                                        </div>
                                    }

                                    {question.questionType === "textarea" &&
                                        <div className='answer-display new-lines'>
                                            {(typeof answers["question-" + question.id] !== "undefined") ? answers["question-" + question.id] : ""}
                                        </div>
                                    }

                                    {question.questionType === "wysiwyg" &&
                                        <div className='answer-display' dangerouslySetInnerHTML={{ __html: answers["question-" + question.id] }}></div>
                                    }

                                    {question.questionType === "files" &&
                                        <DownloadsContainer question={question} value={ (typeof answers["question-" + question.id] !== "undefined") ? answers["question-" + question.id] : [] } />
                                    }                                   
                                </div>
                            )}
                        </Fragment>
                    )}

                </div>
            </div>
        </div>
    );
}

export default View;
