import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Utilities
import Fetching from '../../../utilities/fetching';
import Encryption from '../../../utilities/encryption';

// State management
import { useCookies } from 'react-cookie';
import useFlash from '../../../store/flash';

import Images from '../../../components/admin/images/images';

function ImagesContainer(props) {

    // State management
    let encSecret = process.env.REACT_APP_ENC_KEY;
    
    const [btnTxt, setBtnTxt] = useState("Upload image to this folder");
    const [images, setImages] = useState([]);
    const [folders, setFolders] = useState([]);
    const [folder, setFolder] = useState(false);
    const [parent, setParent] = useState(0);
    const [createFolderName, setCreateFolderName] = useState("");

    const [error, setError] = useState(false);
    const [flashState, flashActions] = useFlash();
    const [authCookie] = useCookies([process.env.REACT_APP_AUTH_COOKIE]);

    const navigate = useNavigate();

    // Load up the folders 
    let loadFolders = () => {

        let authInfo = Encryption.extractHeaders(authCookie);

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + '/folders/img', {
            method: 'GET',
            headers: {
                "Content-type": "application/json",
                'api-token': authInfo.apiToken,
                'api-key': authInfo.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            if (data.rslt === 'success') {
                setFolders(data.detail);
            } else {
                setFolders([]);
            }

        })
        .catch(function (error) {
            if (error === 'auth') {
                flashActions.set({ msg: "Sorry but we could not find the page you are looking for. PLease sign in and try again.", style: "red" });
            }
        });
    }

    const createFolder = ( e ) => {

        e.preventDefault();
        setError(false);

        let authInfo = Encryption.extractHeaders(authCookie);

        // Set up the save data request
        let data = {
            "name": createFolderName,
            "parentId": (folder) ? folder.ID : 0,
            "type": "img"
        }
   
        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + '/folders', {
            method: 'post',
            headers: {
                "Content-type": "application/json",
                'api-token': authInfo.apiToken,
                'api-key': authInfo.apiKey
            },
            body: JSON.stringify(data)
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            if (data.rslt === 'success') {
                setFolder(data.detail);
                setCreateFolderName("");
                loadFolders();
                return;
            } 
            setError({ msg: "Sorry but there was an error savng the folder. Please try again.", style: "red" });

        })
        .catch(function (error) {
            if (error === 'auth') {
                flashActions.set({ msg: "Sorry but we could not save the folder.", style: "red" });
            }
        });

    }

    const selectFolder = ( e, folder) => {

        e.preventDefault();
        setFolder(folder);
        setParent(folder.ParentID);

    }

    // Upload image - firstly get the pre-signed URL 
    const uploadImage = ( e ) => {

        e.preventDefault();
        setError(false);
        setBtnTxt("Uploading...");

        let authInfo = Encryption.extractHeaders(authCookie);

        // Set up the save data request
        let data = {
            "filename": e.target.files[0].name,
            "mimeType": e.target.files[0].type,
            "fileType": "img",
            "isPublic": true,
            "folderId": (folder) ? folder.ID : 0
        }
   
        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + '/files/upload', {
            method: 'post',
            headers: {
                "Content-type": "application/json",
                'api-token': authInfo.apiToken,
                'api-key': authInfo.apiKey
            },
            body: JSON.stringify(data)
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            if (data.rslt === 'success') {
                doUpload(e.target.files[0], data.detail.url, data.detail.acl, data.detail.fileId)
                return;
            } 
            setError({ msg: "Sorry but there was an error uploading the file. Please refrsh the page and try again.", style: "red" });
            setBtnTxt("Upload image to this folder");

        })
        .catch(function (error) {
            setBtnTxt("Upload image to this folder");
            setError({ msg: "Sorry but there was an error uploading the file. Please refrsh the page and try again.", style: "red" });
        });

    }

    // Once we have the signed URL from the back-end 
    // Do the actual uploacd to S3 / Spaces
    const doUpload = ( file, signedUrl, aclEntry, fileId ) => {

        // Check the server side to see what the status of this auth is...
        fetch(signedUrl, {
            method: 'PUT',
            headers: {
                "Content-type": file.type,
                'x-amz-acl': aclEntry
            },
            body: file
        })
        .then(Fetching.statusCheck)
        .then(function () {

            console.log("SUCCESS UPLOADING");
            confirmUpload(fileId);

        })
        .catch(function (error) {
            setBtnTxt("Upload image to this folder");
            setError({ msg: "Sorry but there was an error uploading the file. Please refrsh the page and try again.", style: "red" });
        });

    }

    // Confirm the success of the upload 
    const confirmUpload = ( fileId ) => {

        let authInfo = Encryption.extractHeaders(authCookie);
   
        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + '/files/confirm/img/' + fileId, {
            method: 'get',
            headers: {
                "Content-type": "application/json",
                'api-token': authInfo.apiToken,
                'api-key': authInfo.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            setBtnTxt("Upload image to this folder");
            if (data.rslt === 'success') {
                loadImages();
                return;
            } 
            setError({ msg: "Sorry but there was an error uploading the file. Please refresh the page and try again.", style: "red" });

        })
        .catch(function (error) {
            setBtnTxt("Upload image to this folder");
            setError({ msg: "Sorry but there was an error uploading the file. Please refresh the page and try again.", style: "red" });
        });

    }

    // Load up the folders 
    let loadImages = () => {

        let authInfo = Encryption.extractHeaders(authCookie);

        var folderId = (folder && typeof folder !== "undefined") ? folder.ID : 0;

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + '/folder/images/' + folderId, {
            method: 'GET',
            headers: {
                "Content-type": "application/json",
                'api-token': authInfo.apiToken,
                'api-key': authInfo.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            if (data.rslt === 'success') {
                setImages(data.detail);
            }

        })
        .catch(function (error) {
            if (error === 'auth') {
                flashActions.set({ msg: "Sorry but we could not find the page you are looking for. PLease sign in and try again.", style: "red" });
            }
        });
    }

    // Load up the folders 
    let deleteImage = (e, imgId) => {

        e.preventDefault();
        if (!window.confirm("Are you sure you want to delete this image?")) {
            return;
        }

        let authInfo = Encryption.extractHeaders(authCookie);

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + '/images/' + imgId, {
            method: 'DELETE',
            headers: {
                "Content-type": "application/json",
                'api-token': authInfo.apiToken,
                'api-key': authInfo.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            if (data.rslt === 'success') {
                loadImages();
            }

        })
        .catch(function (error) {
            setError({ msg: "Sorry but there was an error deleting the image", style: "red" });    
        });
    }

    // Select an image 
    const selectImage = (e, img) => {
        e.preventDefault();
        if (typeof props.fieldKey !== "undefined") {
            props.selectImage(img, props.fieldKey);
        } else {
            props.selectImage(img);
        }
    }

    /* eslint-disable */
    useEffect(() => {
        loadFolders();
        loadImages();
    }, []);
    useEffect(() => {
        loadImages();
    }, [folder]);
    /* eslint-enable */

    return (
        <Images 
            images={images}
            folders={folders}
            folder={folder}
            parent={parent}
            createFolderName={createFolderName}
            setCreateFolderName={setCreateFolderName}
            createFolder={createFolder}
            selectFolder={selectFolder}
            toggleImageManager={props.toggleImageManager}
            uploadImage={uploadImage}
            deleteImage={deleteImage}
            selectImage={selectImage}
            btnTxt={btnTxt}
            possibleSizes={props.possibleSizes}
            requiredSize={props.requiredSize}
        />
    );
}

export default ImagesContainer;

