// Core react and plugins
import React, { Fragment } from 'react';
import ImagesContainer from '../../../containers/admin/images/images-container';
import PageSidebar from '../common/page-sidebar';
import ImageInput from '../images/image-input';

// Components
import Error from './../common/forms/error';


// Component class itself
function Slideshow(props) {

    return (
        <Fragment>
            {props.showManager &&
                <ImagesContainer toggleImageManager={ props.toggleImageManager} requiredSize={ props.requiredSize} fieldKey={ props.selectedKey } selectImage={ props.selectImage} image={ props.image} />
            }

            <PageSidebar />

            <div className="width-9">
                <button onClick={props.saveSlideshow} className="right">{ props.saveTxt }</button>
                <h1>Manage Slideshow</h1>

                <Error error={props.error} />

                { props.slides.length === 0 ? (
                    <div className="empty">
                        <p>There are not currently any slides to display</p>
                    </div>
                ) : (
                    <Fragment>
                        { props.slides.map( (slide, i) => 
                            <div key={ "slide-" + i } className="slide">
                                <button className="del-btn right" onClick={ () => props.deleteSlide(i) }>Delete</button>
                                {i < (props.slides.length - 1) &&
                                    <button className="move-btn down right" onClick={() => props.moveSlide(i, "down")}>Move down</button>
                                }
                                { i > 0 && 
                                    <button className="move-btn up right" onClick={() => props.moveSlide(i, "up")}>Move up</button>
                                }
                                
                                <h5>Slide { i + 1 }</h5>
                                <div className="lft-part">
                                    <ImageInput
                                        imageId={slide.imageId}
                                        label="Slideshow image"
                                        toggleImageManager={props.toggleImageManager}
                                        fieldKey={i}
                                    />
                                </div>
                                
                                <div className="rght-part">
                                    <div className="input text">
                                        <label htmlFor={"title-" + i}>Slide title</label>
                                        <input type="text" id={"title-" + i} value={slide.title} onChange={(e) => props.updateSlide(i, "title", e.target.value)} />
                                    </div>
                                    <div className="input text">
                                        <label htmlFor={"intro-" + i}>Slide intro</label>
                                        <input type="text" id={"intro-" + i} value={slide.intro} onChange={(e) => props.updateSlide(i, "intro", e.target.value)} />
                                    </div>
                                    <div className="input text">
                                        <label htmlFor={"lnk-" + i}>Slide link</label>
                                        <input type="text" id={"lnk-" + i} value={slide.link} onChange={(e) => props.updateSlide(i, "link", e.target.value)} />
                                    </div>
                                    <div className="input text">
                                        <label htmlFor={"title-" + i}>Slide link text</label>
                                        <input type="text" id={"lnkTxt-" + i} value={slide.linkText} onChange={(e) => props.updateSlide(i, "linkText", e.target.value)} />
                                    </div>
                                </div>
                            </div>
                        )}
                    </Fragment>
                )}

                <button onClick={props.addSlide} className="add-btn">(+) Add slide</button>
            </div>
        </Fragment>
    )

}

export default Slideshow;