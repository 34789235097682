
// Check the status of a fetch request
const ExtractContent = {
  block: function (block, contentType = "live") {

    let content = (block.liveContent.content !== "") ? JSON.parse(block.liveContent.content) : false;
    
    // If draft content is an option then offer it
    if (contentType === "draft" && block.draftContent.content !== "") {
      content = JSON.parse(block.draftContent.content);
    }

    return content;
    
  }
}

export default ExtractContent;
