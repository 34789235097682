// Core react and plugins
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';

// Utilities
import Fetching from '../../utilities/fetching';
import useAuth from '../../store/auth';
import CryptoJS from 'crypto-js';

// View components
import Activate from '../../components/account/activate';

const ActivateContainer = (props) => {

    // State management
    let encSecret = process.env.REACT_APP_ENC_KEY;
    let cookieName = process.env.REACT_APP_AUTH_COOKIE;
    
    const [authCookie, setCookie] = useCookies([process.env.REACT_APP_AUTH_COOKIE]); // eslint-disable-line
    const [activated, setActivated] = useState(false);
    const [error, setError] = useState(false);
    const [authState, authActions] = useAuth(); // eslint-disable-line

    const urlPath = useParams();

    // Submit the entered passwords
    const activate = () => {

        var actBody = {
            token: urlPath.token,
            key: urlPath.key
        };

        // Submit the login request
        fetch(process.env.REACT_APP_API_BASE + '/activate-account', {
            method: 'post',
            headers: {
                "Content-type": "application/json; charset=utf-8"
            },
            body:JSON.stringify(actBody)
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            if (data.rslt === 'success') {

                // Set a success message and tell them that they need to verify their email address
                setError({ msg: data.msg, style: 'grn' });
                setActivated(true);

                // Authenticate the user 
                const dataToStore = {
                    apiKey: data.detail.key,
                    apiToken: data.detail.token,
                    client: data.detail.usrDetail.Client,
                    email: data.detail.usrDetail.Email,
                    firstName: data.detail.usrDetail.FirstName,
                    lastName: data.detail.usrDetail.LastName,
                    manager: data.detail.usrDetail.Manager,
                    mobileNo: data.detail.usrDetail.MobileNo,
                    permissions: data.detail.usrDetail.Permissions,
                    ref: data.detail.usrDetail.Ref
                }

                // Upon successful login, store the session info to state (and into an encrypted cookie for more permanent storage)
                authActions.storeSession(dataToStore);
                var encData = JSON.stringify({ u: CryptoJS.AES.encrypt(JSON.stringify(dataToStore), encSecret).toString() });
                setCookie(cookieName, encData, { path: '/', secure: true, sameSite: true });

                return;
            } 
            
            setError({ msg: data.msg, style: "red" });
        })
        .catch(function (error) {
            setActivated(false);
            setError({ msg: "Sorry but there was a problem activating your account. Please try again.", style: "red" });
        });

    }

    // useEffect to load up the auth check when the component mounts
    /* eslint-disable */
    useEffect(() => {
        activate();
    }, []);
    /* eslint-enable */

    return (
        <Activate
            error={ error }
            activated={ activated }
        />
    );
}

export default ActivateContainer;
