import DisplayFile from '../../files/display-file';
import ExtractContent from '../../../utilities/extract-content';

function PlainTxtCentre(props) {

    let content = ExtractContent.block(props.block);
    if (!content) return "";

    return (
        <section className="plain">

            <div className="content">

                <div className="width-8 centred">
                    { content.title && content.title !== "" && 
                        <h2 className='text-centre'>{ content.title }</h2>
                    }
                    { content.content && content.content !== "" && 
                        <div className='text-centre' dangerouslySetInnerHTML={{ __html: content.content }}></div>
                    }

                    <DisplayFile files={typeof content["files"] !== "undefined" ? content["files"] : []} />
                </div>    
            </div>
            
        </section>
    );
}

export default PlainTxtCentre;
