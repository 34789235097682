// Core react and plugins
import React, { useState } from 'react';

// View components
import CreateBlockInput from '../../../../components/blocks/cms/create-block-input';

const CreateBlockInputContainer = (props) => {

    // State management
    const [content, setContent] = useState({ title: "" });



    // // Handle the update of the text input 
    const handleUpdate = (field, value) => {
        var newContent = { ...content };
        newContent[field] = value;
        setContent(newContent);
    }
    const handleBlockCreate = (e) =>{
        e.preventDefault();
        props.closeFunc(e);
        //call create block function in parent page
        props.createBlock(e,props.blockStyle,content.title) 
    }


    return (
        <CreateBlockInput handleBlockCreate={handleBlockCreate} handleUpdate={handleUpdate} blockStyle={props.blockStyle} content={content} {...props} />
    );
}

export default CreateBlockInputContainer;
