import React, {useState} from 'react';
import jsPDF from 'jspdf'
import moment from "moment";

import './../../../assets/fonts/Jost-Light-normal.js';
import './../../../assets/fonts/Jost-Regular-normal.js';
import './../../../assets/fonts/Jost-SemiBold-normal.js';

const Download = (props) => {

    const [btnTxt, setBtnTxt] = useState("Download");

    const headerFontSize = 14;
    const subheaderFontSize = 12;
    const color = '#222222';
    const colorFaded = '#777777';
    const red = '#D31C35';
    const bodyFontSize = 8.5;
    const bodyHeadSize = 9.5;
    const fadedRed = '#e3b6bb';

    const categoryMap = {
        "uk-arts": "UK Arts",
        "uk-social": "UK Social Concern",
        "international": "International"
    }

    const generatePDF = () => {

        setBtnTxt("Downloading...");

        var doc = new jsPDF({
            orientation: "p",
            format: "a4",
            unit: "mm",
            putOnlyUsedFonts: true
        });

        // Add the content to the doc
        doc = addTemplate(doc);

        // Add in the questions and answers
        var availableHeight = 205;
        var usedHeight = 0;
        var itemHeight = 0;
        var titleHeight = 0;
        var startPosn = 0;
        for (var i in props.application) {

            var question = props.application[i];
            var ans = question.answer;

            if (question.questionType === "files") {
                continue;
            }

            // Calcuate the potential height of the question and answer
            var answer = ans.replace(/&#39;/g, "'");
            if (question.questionType === "select") {
                answer = categoryMap[answer];
            }
            if (question.questionType === "wysiwyg") {
                answer = answer.replace(/<\/p>/g, "\n\n").replace(/<p>/g, "").replace(/<br>/g, "\n").replace(/<[^>]+>/g, "");
            }

            doc.setFontSize(bodyHeadSize)
            doc.setTextColor(color)
            doc.setFont('Jost-SemiBold', 'normal');
            titleHeight = doc.getTextDimensions(question.question, { align: "left", maxWidth: 190 }).h;
            itemHeight += titleHeight;

            doc.setFont('Jost-Light', 'normal');
            doc.setFontSize(bodyFontSize)
            itemHeight += doc.getTextDimensions(answer, { align: "left", maxWidth: 190 }).h;

            // If the item height is greater than the available height, then add a new page
            if ((usedHeight + itemHeight) > availableHeight + 22) {
                doc.addPage();
                doc = addTemplate(doc);
                startPosn = 52;
            } else {
                startPosn = (usedHeight === 0) ? 52 : usedHeight + 15;
            }
            usedHeight = startPosn + itemHeight;
            itemHeight = 0;

            // Append the content to the page
            doc.setFontSize(bodyHeadSize)
            doc.setTextColor(color)
            doc.setFont('Jost-SemiBold', 'normal');
            doc.text(10, startPosn, question.question, { align: "left", maxWidth: 190 })

            doc.setFont('Jost-Light', 'normal');
            doc.setFontSize(bodyFontSize)
            doc.setTextColor(color)
            
            doc.text(10, startPosn + titleHeight + 2, answer, { align: "left", maxWidth: 190 })

        }

        // Run the export
        var repDate = moment().format("YYYYMMDDHHmmss");
        var initials = getInitials("Fred", "Smith");
        doc.save('mt-application-' + initials + "-" + repDate + '.pdf')

        setTimeout(() => {
            setBtnTxt("Download");
        }, 2500);

    }

    // Get the initials of the user
    const getInitials = (firstName, lastName) => {

        var fn = firstName.split("-");
        var ln = lastName.split("-");

        var initials = "";
        for (var i in fn) { 
            if (fn[i].length > 0) {
                initials += fn[i].charAt(0).toLowerCase();
            }
        }
        for (var j in ln) {
            if (ln[j].length > 0) {
                initials += ln[j].charAt(0).toLowerCase();
            }
        }

        return initials;

    }

    // Add template 
    const addTemplate = (doc) => {
        // Page header 
        doc.addImage("/pdf-img/red-logo.png", "PNG", 10, 10, 80, 20);

        doc.setFont('Jost-SemiBold', 'normal');
        doc.setFontSize(headerFontSize)
        doc.setTextColor(color)
        doc.text(200, 20, "The Morel Trust Application", { align: "right" })

        var org = "";
        for (var j in props.application) {
            if (props.application[j].questionId === 2) {
                org = props.application[j].answer;
            }
        }

        var name = org;
        doc.setFont('Jost-Light', 'normal');
        doc.setFontSize(subheaderFontSize)
        doc.text(200, 26, name, { align: "right" })

        doc.setFillColor(red)
        doc.roundedRect(10, 36, 190, 1, 0.5, 0.5, 'F');

        // Footer 
        doc.setFillColor(fadedRed)
        doc.circle(0, 312, 55, 'F');
        doc.circle(210, 418, 150, 'F');

        doc.setFont('Jost-Light', 'normal');
        doc.setFontSize(5)
        doc.setTextColor(colorFaded)
        doc.text(105, 293, "© 2023 The Morel Trust", { align: "center" })

        return doc;
    }

    if (!props.application) {
        return "";
    }

    return (
        <button onClick={generatePDF} className="btn primary right">{ btnTxt }</button>
    );
}
export default Download;