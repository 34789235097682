import React, {Fragment, useState} from 'react';
import { Link } from 'react-router-dom';
import DisplayImageContainer from '../../../containers/images/display-image-container';

function ImageInput(props) {

    const [imageName, setImageName] = useState(false);

    return (
        <div className="input">
            <label>{ props.label } { props.imageId !== 0 && typeof props.clearFunc !== "undefined" && <button className="txt" onClick={ () => props.clearFunc() }>(Remove image)</button> }</label>
            <div className={ props.imageId ? "img-input populated" : "img-input"}>
                { props.imageId ? (
                    <Fragment>
                        <DisplayImageContainer imageId={ props.imageId } size="tmb" class="sel-thumb" returnName={ setImageName } />
                        { imageName ? (
                            <span>Update image <span>({ imageName })</span></span>
                        ) : (
                            <span>Select image</span>
                        )}
                    </Fragment>
                ) : (
                    <span>Select image</span>
                )}
                <Link to="#image-manage" onClick={(e) => props.toggleImageManager(e, props.size, props.fieldKey)}>&nbsp;</Link>
            </div>
        </div>
    );
}

export default ImageInput;

