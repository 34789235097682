// TODO: Handling CSRF attacks?!?!
// Check current auth status
// User will have been routed elsewhere if they have already logged in. So there should be no need to do an auth check on this component

// Core react and plugins
import React, { useState, useEffect } from 'react';

// Utilities
import Fetching from '../../../../utilities/fetching';
import CryptoJS from 'crypto-js';

// State management
import useAuth from '../../../../store/auth';
import useFlash from '../../../../store/flash';
import { useCookies } from 'react-cookie';

// View components
import Login from './../../../../components/admin/users/auth/login';

const LoginContainer = () => {

    // State management
    let cookieName = process.env.REACT_APP_AUTH_COOKIE;
    let encSecret = process.env.REACT_APP_ENC_KEY;
    const [rdr, setRdr] = useState(false);// eslint-disable-line
    const [error, setError] = useState(false);
    const [btnTxt, setBtnTxt] = useState("Sign in");
    const [authInfo, setAuthInfo] = useState({ email: false, password: false });
    const [authState, authActions] = useAuth(); // eslint-disable-line
    const [flashState, flashActions] = useFlash();
    const [authCookie, setCookie] = useCookies([cookieName]);// eslint-disable-line

    
    // Update the auth info local state (based upon entered info)
    const handleAuthInfo = ( fieldname, val ) => {
        var newInfo = { ...authInfo };
        newInfo[fieldname] = val;
        setAuthInfo(newInfo);
    }

    // Login function
    // Submit the entered login info to the login endpoint
    // Upon success redirect (either to the MFA setup or MFA verification)
    const login = (e) => {

        e.preventDefault();
        setBtnTxt("Signing in...");

        var data = {
            email:authInfo.email,
            password:authInfo.password
        }

        // Submit the login request
        fetch(process.env.REACT_APP_API_BASE + '/users/login', {
            method: 'post',
            headers: {
                "Content-type": "application/json; charset=utf-8"
            },
            body: JSON.stringify(data)
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            if (data.rslt === 'success' && typeof data.token != "undefined" && typeof data.key != "undefined") {

                const dataToStore = {
                    apiKey:data.key,
                    apiToken:data.token,
                    client:data.usrDetail.Client,
                    email:data.usrDetail.Email,
                    firstName:data.usrDetail.FirstName,
                    lastName:data.usrDetail.LastName,
                    manager:data.usrDetail.Manager,
                    mobileNo:data.usrDetail.MobileNo,
                    permissions:data.usrDetail.Permissions,
                    ref:data.usrDetail.Ref
                }

                // Upon successful login, store the session info to state (and into an encrypted cookie for more permanent storage)
                authActions.storeSession(dataToStore);
                var encData = JSON.stringify({u: CryptoJS.AES.encrypt( JSON.stringify( dataToStore ), encSecret).toString()});
                setCookie(cookieName, encData, { path: '/', secure: true, sameSite: true });

                // Redirect to the appropriate page for the permissions 
                if (data.usrDetail.Permissions.Manage === true) {
                    setRdr('/admin/applications')
                    return;
                }
                setRdr("/account");
            } else {

                // Upon failure - display an error
                if (typeof data.error != 'undefined') {
                    setError({ msg: data.error, style: "red" });
                } else {
                    setError({ msg: "Sorry but there was a problem signing you in. Please check your details and try again.", style: "red" });
                }

                setBtnTxt("Sign in");
            }
            

        })
        .catch(function (error) {
            setBtnTxt("Sign in");
            setError({ msg: "Sorry but there was a problem signing you in. Please check your details and try again.", style: "red" });
        });

    }

  

    // useEffect to load up the auth check when the component mounts
    /* eslint-disable */
    useEffect(() => {
        if ( flashState.msg ) {
            setError(flashState);
            flashActions.clear();
        }
    }, []);
    /* eslint-enable */

    return (
        <Login
            loginFunc={ login }
            handleAuthInfo={ handleAuthInfo }
            error={ error }
            rdr={ rdr }
            btnTxt={ btnTxt }
        />
    );
}

export default LoginContainer;
