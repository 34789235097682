import React, { Fragment } from 'react';
import { Link } from 'react-router-dom'

import Loader from '../admin/common/loader';
import TextInput from './dynamic-fields/text';
import TextareaInput from './dynamic-fields/textarea';
import EmailInput from './dynamic-fields/email';
import SelectInput from './dynamic-fields/select';
import WysiwygInput from './dynamic-fields/wysiwyg';
import Error from '../admin/common/forms/error';
import BlockBarContainer from '../../containers/admin/blocks/cms/block-bar-container';
import Permissions from '../../utilities/permissions';
import MtLogo from './../../assets/img/mt/logo.webp';
import FilesContainer from '../../containers/account/fields/files-container';
import { Helmet } from 'react-helmet';

const Apply = (props) => {

    let answers = {};
    for (var i in props.answers) {
        answers["question-" + props.answers[i].questionId] = props.answers[i].answer;
    }

    return (
        <div className="sided">
            <Helmet>
                <title>Apply for a grant :: The Morel Trust</title>
            </Helmet>

            {props.authState && Permissions.actionCheck("admin", "all", props.authState.permissions) &&
                <BlockBarContainer {...props} />
            }

            <div className='logo-sxn'>
                <img src={MtLogo} alt="Morel Trust logo" />

                <nav>
                    <ul>
                        <li><Link to="/account">My Account</Link></li>
                        <li><Link to="/apply">Create Application</Link></li>
                        <li className='btm-lnk'><Link to="/u/logout">Sign out</Link></li>
                    </ul>
                </nav>
            </div>
            <div className="core">
                <div className="rght-content">

                    <h1>Apply for a grant</h1>
                    <Error error={props.error} />

                    <p>To apply for a grant from The Morel Trust, simply complete the form below with details of your project.</p>
                    <p>Your responses will be saved as you proceed. If you need to leave and return to your application later, you can find it in your &quot;<Link to="/account">My account</Link>&quot; page.</p>

                    { props.loading ? (
                        <Loader />
                    ) : (
                        <Fragment>
                            { props.questions.map((question, index) => 
                                <Fragment key={"1-" + index}>
                                    { question.questionType === "text" &&
                                        <TextInput
                                            label={question.question}
                                            name={"question-" + question.id}
                                            handleResponse={props.handleResponse}
                                            saveResponse={props.saveResponse}
                                            valErrors={props.valErrors}
                                            value={ (typeof answers["question-" + question.id] !== "undefined") ? answers["question-" + question.id] : ""}
                                        />
                                    }
                                    {question.questionType === "textarea" &&
                                        <TextareaInput
                                            label={question.question}
                                            name={"question-" + question.id}
                                            handleResponse={props.handleResponse}
                                            saveResponse={props.saveResponse}
                                            valErrors={props.valErrors}
                                            value={ (typeof answers["question-" + question.id] !== "undefined") ? answers["question-" + question.id] : ""}
                                        />
                                    }
                                    {question.questionType === "email" &&
                                        <EmailInput
                                            label={question.question}
                                            name={"question-" + question.id}
                                            handleResponse={props.handleResponse}
                                            saveResponse={props.saveResponse}
                                            valErrors={props.valErrors}
                                            value={ (typeof answers["question-" + question.id] !== "undefined") ? answers["question-" + question.id] : ""}
                                        />
                                    }
                                    {question.questionType === "select" &&
                                        <SelectInput
                                            label={question.question}
                                            name={"question-" + question.id}
                                            options={JSON.parse(question.options)}
                                            handleResponse={props.handleResponse}
                                            saveResponse={props.saveResponse}
                                            valErrors={props.valErrors}
                                            value={ (typeof answers["question-" + question.id] !== "undefined") ? answers["question-" + question.id] : ""}
                                        />
                                    }
                                    {question.questionType === "wysiwyg" &&
                                        <WysiwygInput
                                            label={question.question}
                                            name={"question-" + question.id}
                                            handleResponse={props.handleResponse}
                                            saveResponse={props.saveResponse}
                                            valErrors={props.valErrors}
                                            value={ (typeof answers["question-" + question.id] !== "undefined") ? answers["question-" + question.id] : ""}
                                        />
                                    }

                                    {question.questionType === "files" &&
                                        <FilesContainer
                                            label={question.question}
                                            name={"question-" + question.id}
                                            handleResponse={props.handleResponse}
                                            value={ (typeof answers["question-" + question.id] !== "undefined") ? answers["question-" + question.id] : false}
                                        />
                                    }
                                </Fragment>
                            )}

                        </Fragment>
                    )}

                    <button className="btn btn-primary" onClick={props.submit}>Submit</button>
                </div>
            </div>
        </div>
    );
}

export default Apply;

// TODO: File uploads