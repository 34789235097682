// Core react and plugins
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Utilities
import Fetching from './../../../utilities/fetching';
import Encryption from '../../../utilities/encryption';

// State management
import { useCookies } from 'react-cookie';
import useFlash from './../../../store/flash';

import UserList from './../../../components/admin/users';

const UserListContainer = () => {

    // State management
    let encSecret = process.env.REACT_APP_ENC_KEY;
    const [loading, setLoading] = useState(false); // eslint-disable-line
    const [llEnabled, setLlEnabled] = useState(true); // eslint-disable-line
    const [error, setError] = useState(false);
    const [lbError, setLbError] = useState(false);
    const [users, setUsers] = useState([]);
    const [flashState, flashActions] = useFlash();
    const [authCookie] = useCookies([process.env.REACT_APP_AUTH_COOKIE]);
    const [userReports,setUserReports] = useState([])
    const [nextPage, setNextPage] = useState(1); // eslint-disable-line
    const [searchActive, setSearchActive] = useState(false); // eslint-disable-line
    const [searchEntry,setSearchEntry] = useState('');
    const [client,setClient] = useState(0);
    const [manager, setManager] = useState(0);
    const [clients, setClients] = useState([]);
    const [userPermissions,setUserPermissions] = useState({})
    const [lightBoxOpen,toggleLightBoxOpen] = useState(false)
    const [lightboxView,setLightBoxView] = useState(false)
    const [passwordResetUrl,setPasswordResetUrl] = useState(false)
    // const [passwordResetlightBoxOpen,togglePasswordResetLightBoxOpen] = useState(false)

    let navigate = useNavigate();

    let loginUrl = "/";
    let redirectUrl = '/admin/dashboard'

    // Check that we have a partially complete auth status (based upon the cookie)
    let loadUsers = (isSearch) => {

        if (loading) return;
        if (!llEnabled && nextPage > 1 && typeof isSearch == "undefined") return;

        setLoading(true);

        let authInfo = Encryption.extractHeaders(authCookie);

        let data = {
            clientId: parseInt(client),
            managerId: parseInt(manager),
            search: searchEntry
        }

        var page = nextPage;
        if (isSearch) {
            setUsers([]);
            setNextPage(1);
            setLlEnabled(true);
            page = 1;
            setSearchActive(true);
        }

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + '/users/list?limit=10&page=' + page, {
            method: 'post',
            headers: {
                "Content-type": "application/json; charset=utf-8",
                'api-token': authInfo.apiToken,
                'api-key': authInfo.apiKey
            },
            body:JSON.stringify(data)
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {
            // Set the state for the users

                setLoading(false);

                if (data === null) {
                    if (page === 1) {
                        setUsers([]);
                    }
                    setLlEnabled(false);
                } else {

                    for(let i = 0; i<data.length;i++){
        
                        if(data[i].Ref === authInfo.ref){
                            data[i].hideLink = true
                        } else {
                            data[i].hideLink = false
                        }
                    }

                    if (data.length === 0) {
                        setLlEnabled(false);
                    } else if (page === 1) {
                        setUsers(data)
                    } else {
                        // This is an update via lazy load 
                        var newUsers = [...users];
                        for (var i in data) {
                            newUsers.push(data[i]);
                        }
                        setUsers(newUsers);
                    }

                    setNextPage(page + 1);
                    setLlEnabled(true);

                }

            // setError({ msg: "Sorry but there was an error loading the users.", style: "red" });
            setLoading(false);

        })
        .catch(function (error) {
            if (error === 'auth') {
                flashActions.set({ msg: "Sorry but we could not find the page you are looking for. Please sign in and try again.", style: "red" });
            }
            navigate(loginUrl);
        });
    }

    // Check that we have a partially complete auth status (based upon the cookie)
    let getUserReports = (userId,e) => {
        e.preventDefault()

        setUserReports([]);

        let authInfo = Encryption.extractHeaders(authCookie);

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + '/users/reports/'+userId, {
            method: 'get',
            headers: {
                "Content-type": "application/json; charset=utf-8",
                'api-token': authInfo.apiToken,
                'api-key': authInfo.apiKey
            },
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {
            // Set the state for the users
                if(data.rslt === 'success'){

                   /* if(data.detail.length === 1 && data.detail[0].submissionDate !== ""){
                        navigate('/admin/reports/'+data.detail[0].id)
                        // return
                    } else*/

                    setLightBoxView('report')
                    if (data.detail.length > 0){
                        setUserReports(data.detail);
                        toggleLightBoxOpen(true)
                    } else {
                        toggleLightBoxOpen(true)
                    }

                    
                } else {
                    setError({msg:'Sorry, there was an error loading data',style:'red'})
                }
            
        })
        .catch(function (error) {
            if (error === 'auth') {
                flashActions.set({ msg: "Sorry but we could not find the data you are looking for. Please sign in and try again.", style: "red" });
                navigate(loginUrl);
            }
        });
    }

    const deleteUser = ( userEmail,e ) => {

        e.preventDefault();
        setError(false);

        let doDelete = window.confirm('Do you wish to delete this employee?  This cannot be undone');

        if(!doDelete){
            return false;
        }
        
        let authInfo = Encryption.extractHeaders(authCookie);

        var url =   '/users/delete'

        var data ={
            usrEmail:userEmail
        }
        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + url, {
            method: 'POST',
            headers: {
                'api-token': authInfo.apiToken,
                'api-key': authInfo.apiKey
            },
            body:JSON.stringify(data)
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {
            

            setError({ msg: 'The employee has been deleted', style: "grn" });
            loadUsers()
            // flashActions.set({ msg: message, style: "grn" });
            // navigate("/admin/manage/clients");

        })
        .catch(function (error) {
            setError({ msg: "Sorry but there was an error deleting the employee", style: "red" });
        });

    }

    // Reset a user so they can take the questionnaire again
    const resetUsr = (e, uid) => {
        
        e.preventDefault();

        let authInfo = Encryption.extractHeaders(authCookie);

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + "/tests/refresh/real/" + uid, {
            method: 'get',
            headers: {
                'api-token': authInfo.apiToken,
                'api-key': authInfo.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {
            setLbError({ msg: "The employee has been reset so they can take the self-assessment again.", class: "flash-msg grn" });

        })
        .catch(function (error) {
            setLbError({ msg: "Sorry but there was an error deleting the employee", class: "flash-msg red" });
        });
    }

    // Reset a user so they can take the questionnaire again
    const resetUsrPw = (uid,e) => {

        e.preventDefault()

        let passwordResetConfirmed = window.confirm("This will reset this employee's password.  Do you wish to continue?")

        if(!passwordResetConfirmed){
            return
        }

        let authInfo = Encryption.extractHeaders(authCookie);

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + "/users/manager-password/" + uid, {
            method: 'get',
            headers: {
                'api-token': authInfo.apiToken,
                'api-key': authInfo.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            if (typeof data.rslt != "undefined" && data.rslt === "success") {
                setLightBoxView('resetPassword')
                setPasswordResetUrl(data.detail);
                toggleLightBoxOpen(true)
                // setLbError({ msg: "The user has been reset so they can answer the questionnaire again.", class: "flash-msg grn" });
                return;
            }

            setLbError({ msg: "Sorry but there was an error resetting the employee.", class: "flash-msg" });

        })
        .catch(function (error) {
            setError({ msg: "Sorry but there was an error deleting the employee", style: "red" });
        });

    }

    // useEffect to load up the auth check when the component mounts
    useEffect(() => {
        if (flashState.msg) {
            setError(flashState);
            flashActions.clear();
        }
    }, [setError, flashState, flashActions]);

    const checkUserPermissions = () =>{
        let authInfo = Encryption.extractHeaders(authCookie);
        if(!authInfo.permissions.Users){
            navigate(redirectUrl)
            return
        } else {
            setUserPermissions(authInfo.permissions)
            loadUsers()
        }
    }

    const handleScroll = (e) => {
        const bottom = (e.target.scrollHeight - e.target.scrollTop) >= (e.target.clientHeight - 20);
        if (bottom) {
            loadUsers();
        }
    }

    /* eslint-disable */
    useEffect(() => {
        document.title = 'Manage users :: Morel Trust';
        checkUserPermissions()
        loadUsers();
    },[])
    /* eslint-enable */

    const closeLightbox = (e) => {
        e.preventDefault();
        setLightBoxView(false)
        toggleLightBoxOpen(false)
        setLbError(false);
    }

    return (
        <UserList
            error={ error }
            users={ users }
            deleteUser={deleteUser}
            setClient={setClient}
            setManager={ setManager }
            setSearchEntry={ setSearchEntry }
            loadUsers={ loadUsers }
            clients={ clients }
            getUserReports={getUserReports}
            lightBoxOpen={lightBoxOpen}
            toggleLightBoxOpen={toggleLightBoxOpen}
            userReports={userReports}
            resetUsr={ resetUsr }
            userPermissions={userPermissions}
            lbError={ lbError }
            closeLightbox={closeLightbox}
            lightboxView={lightboxView}
            passwordResetUrl={passwordResetUrl}
            resetUsrPw={resetUsrPw}
            handleScroll={ handleScroll }
            loading={loading}
        />
    );
}

export default UserListContainer;
