// Core react and plugins
import React, { Component } from 'react';
import { Link, Navigate } from 'react-router-dom';

import Password from '../../common/forms/password';
import Button from '../../common/forms/button';

// Components
import Error from './../../common/forms/error';
import PwMeter from './pw-meter';
import { Helmet } from 'react-helmet';

// Component class itself
class SetPw extends Component {

    render() {

        if (this.props.rdr) {
            return <Navigate to={this.props.rdr} />
        }

        return (

            <div className={ "smlr-sxn" }>
                <Helmet>
                    <title>Set password :: The Morel Trust</title>
                </Helmet>

                <h1>Set your password</h1>

                <Error error={ this.props.error } />

                <form id="pwForm">

                    <PwMeter { ...this.props } /> 
                    <Password label={'Password'} id={'password'} fieldName="password" handleChange={ this.props.handleUpdate} />
                    <Password label={'Confirm Password'} id={'confirm'} fieldName="confirm" handleChange={ this.props.handleUpdate} />

                    <Button handleClick={ (e) => this.props.submitFunc(e) } btnTxt={ this.props.btnTxt }/>

                </form>

                <p className="sml">Forgotten your password? <Link to="/u/forgotten-password">Reset it here</Link></p>
            </div>

        );

    }
}

export default SetPw;