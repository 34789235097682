import React, { useState, useEffect } from 'react';

// Utilities
import Fetching from '../../../utilities/fetching';
import Encryption from '../../../utilities/encryption';

// State management
import { useCookies } from 'react-cookie';

import FileInput from '../../../components/account/dynamic-fields/file-input';


function FilesContainer(props) {

    // State management   
    const [btnTxt, setBtnTxt] = useState("Upload file");
    const [files, setFiles] = useState([]);

    const [error, setError] = useState(false);
    const [authCookie] = useCookies([process.env.REACT_APP_AUTH_COOKIE]);

    // Upload file - firstly get the pre-signed URL // TODO: 
    const uploadFile = ( e ) => {

        const permittedTypes = ["application/pdf"];
        if (permittedTypes.indexOf(e.target.files[0].type) === -1) {
            setError({ msg: "Sorry but you can only upload PDF files.", style: "red" });
            return;
        }

        e.preventDefault();
        setError(false);
        setBtnTxt("Uploading file...");

        let authInfo = Encryption.extractHeaders(authCookie);

        // Set up the save data request
        let data = {
            "filename": e.target.files[0].name,
            "mimeType": e.target.files[0].type,
            "fileType": "file",
            "isPublic": false,
            "folderId": 0
        }
   
        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + '/files/upload', {
            method: 'post',
            headers: {
                "Content-type": "application/json",
                'api-token': authInfo.apiToken,
                'api-key': authInfo.apiKey
            },
            body: JSON.stringify(data)
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            if (data.rslt === 'success') {
                doUpload(e.target.files[0], data.detail.url, data.detail.acl, data.detail.fileId)
                return;
            } 

            var msg = (typeof data.msg != 'undefined') ? data.msg : "Sorry but there was an error uploading the file. Please refrsh the page and try again.";
            setError({ msg: msg, style: "red" });
            setBtnTxt("Upload file");

        })
        .catch(function (error) {
            setBtnTxt("Upload file");
            setError({ msg: "Sorry but there was an error uploading the file. Please refrsh the page and try again.", style: "red" });
        });

    }

    // Once we have the signed URL from the back-end 
    // Do the actual uploacd to S3 / Spaces
    const doUpload = ( file, signedUrl, aclEntry, fileId ) => {

        // Check the server side to see what the status of this auth is...
        fetch(signedUrl, {
            method: 'PUT',
            headers: {
                "Content-type": file.type,
                'x-amz-acl': aclEntry
            },
            body: file
        })
        .then(Fetching.statusCheck)
        .then(function () {

            console.log("SUCCESS UPLOADING");
            confirmUpload(fileId);

        })
        .catch(function (error) {
            setBtnTxt("Upload file");
            setError({ msg: "Sorry but there was an error uploading the file. Please refrsh the page and try again.", style: "red" });
        });

    }

    // Confirm the success of the upload 
    const confirmUpload = ( fileId ) => {

        let authInfo = Encryption.extractHeaders(authCookie);
   
        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + '/files/confirm/file/' + fileId, {
            method: 'get',
            headers: {
                "Content-type": "application/json",
                'api-token': authInfo.apiToken,
                'api-key': authInfo.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            setBtnTxt("Upload file");
            if (data.rslt === 'success') {
                // loadFiles(fileId, false);
                props.handleResponse(props.name, fileId, true, true, false);
                return;
            } 
            setError({ msg: "Sorry but there was an error uploading the file. Please refresh the page and try again.", style: "red" });

        })
        .catch(function (error) {
            setBtnTxt("Upload file");
            setError({ msg: "Sorry but there was a problem uploading the file. Please refresh the page and try again.", style: "red" });
        });

    }

    // Load up the folders 
    let loadFiles = (addnFileId, removeFileId) => {

        if(!props.value) return;
        let fileIds = (typeof props.value == "object") ?  props.value : JSON.parse(props.value);
        if (addnFileId) fileIds.push(addnFileId);
        if (removeFileId) fileIds = fileIds.filter((fileId) => fileId !== removeFileId);
        if(fileIds.length === 0) return;

        let authInfo = Encryption.extractHeaders(authCookie);

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + '/specific-files', {
            method: 'POST',
            headers: {
                "Content-type": "application/json",
                'api-token': authInfo.apiToken,
                'api-key': authInfo.apiKey
            },
            body: JSON.stringify(fileIds)
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            if (data.rslt === 'success') {
                setFiles(data.detail);
            }

        })
        .catch(function (error) {
            
        });
    }

    // Load up the folders 
    let deleteFile = (e, fileId) => {

        e.preventDefault();
        if (!window.confirm("Are you sure you want to delete this file?")) {
            return;
        }

        let authInfo = Encryption.extractHeaders(authCookie);

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + '/files/' + fileId, {
            method: 'DELETE',
            headers: {
                "Content-type": "application/json",
                'api-token': authInfo.apiToken,
                'api-key': authInfo.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            if (data.rslt === 'success') {
                loadFiles(false, fileId);
                props.removeFile(fileId)
            }

        })
        .catch(function (error) {
            setError({ msg: "Sorry but there was an error deleting the image", style: "red" });    
        });
    }

    // Select a file
    const selectFile = (e, file) => {
        e.preventDefault();
        if (typeof props.fieldKey !== "undefined") {
            props.addFile(file, props.fieldKey);
        } else {
            props.addFile(file);
        }
    }

    /* eslint-disable */
    useEffect(() => {
        loadFiles(false, false);
    }, [props.value]);
    /* eslint-enable */

    return (
        <FileInput 
            files={files}
            uploadFile={uploadFile}
            deleteFile={deleteFile}
            selectFile={selectFile}
            btnTxt={btnTxt}
            label={props.label}
            error={error}
        />
    );
}

export default FilesContainer;

