import React, {useState} from 'react';
import { Link } from 'react-router-dom';

import MtLogo from './../../../assets/img/mt/logo.webp';

function AdminHeader(props){

    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = (e) => {
        e.preventDefault();
        setMenuOpen(!menuOpen);
    }

    return (
        <header className="admin-header">
            <div className='logo-sxn left'>
                <img src={ MtLogo } alt="Morel Trust logo" />
            </div>
            { !props.hide &&
                <div className='admin-menu right'>
                    <nav>
                        <Link className="mobile-menu" to='#menu' onClick={(e) => toggleMenu(e)}>Menu</Link>
                        <ul className={ menuOpen ? "open": "" }>
                            <li><Link to='/'>View site</Link></li>
                            {props.permissions.Manage &&
                                <li><Link to='/admin/pages'>Website content</Link></li>
                            }
                            {props.permissions.Reports &&
                                <li><Link to='/admin/applications'>Applications</Link></li>
                            }
                            {props.permissions.Users &&
                                <li><Link to='/admin/users'>Manage users</Link></li>
                            }
                            <li className="new-sxn"><Link to='/admin/settings'>My account</Link></li>
                            <li className="new-sxn"><Link to='/u/logout'>Logout</Link></li>
                        </ul>
                    </nav>
                </div>
            }
        </header>
    )
}

export default AdminHeader