/* 
    Error view
    
    Props:
        - errorType: If no error is passed then defaults to a 404 Unfound error
*/
// Core react and plugins
import React, { Component } from 'react';

// Assets


// Components


// Component class itself
class Errors extends Component {

    render() {

        return (

            <div className="usr-settings">
                <div className="width-6 centred">
                    <h1>Page not found</h1>
                    <p>Sorry to be a pest, but it seems the page you&rsquo;re looking for does not exist.</p>
                    <p>Please go back and try again</p>
                </div>
            </div>

        );

    }
}

export default Errors;