import React, { Fragment } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
// import GA4React from "ga-4-react";

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend'

import RoutingContainer from './containers/routing-container';
import AuthRoutingContainer from './containers/auth-routing-container';
import PagesContainer from './containers/pages/pages-container';
import Errors from './components/common/errors';
import PageTemplateContainer from './containers/page-template-container';
import ApplyContainer from './containers/account/apply-container';
import AccountContainer from './containers/account/account-container';
import ViewContainer from './containers/account/view-container';


function App() {
  return (
    <Fragment>
      <DndProvider backend={HTML5Backend}>
        <BrowserRouter>
          <Routes>
            
            <Route path='/u/*' element={<RoutingContainer/>} />
            <Route path='/login' element={<RoutingContainer />} />
            <Route path='/admin/*' element={<AuthRoutingContainer isAdmin={true} />} />

            <Route path="/apply/:aid" element={<ApplyContainer />} />
            <Route path="/view-application/:aid" element={<ViewContainer />} />
            <Route exact path="/apply" element={<ApplyContainer />} />
            <Route path="/account" element={<AccountContainer />} />
            <Route path="/" element={<PageTemplateContainer />}>
              <Route path="/p/:slug" element={<PagesContainer />} />
              <Route index element={<PagesContainer />} />
            </Route>

            <Route element={<Errors />} /> 
          </Routes>
        </BrowserRouter>
      </DndProvider>
    </Fragment>
  );
}

// try {
//   setTimeout(_ => {
//     const ga4react = new GA4React("G-66DVNLQC8K");
//     ga4react.initialize().catch(err => console.error(err));
//   }, 4000);
// } catch (err) {
//   console.error(err);
// }

export default App;
