import React from 'react';

function Downloads(props) {

    return (
        <div className="answer-display dl">
                <div className="downloads">
                    { props.files.length > 0 && 
                        <ul>
                            { props.files.map( (file, i) => 
                                <li key={"file-" + i}><button onClick={ (e) => props.download(e, file.ID) } className="plain-btn">{file.Filename}</button></li>    
                            )}
                        </ul>
                    }
                </div>
        </div>
    );
}

export default Downloads;

