// TODO: Handling CSRF attacks?!?!
// Submit an MFA token to complete login

// Core react and plugins
import React, { useState, useEffect } from 'react';

// Utilities
import Fetching from './../../../utilities/fetching';
import Encryption from './../../../utilities/encryption';

// State management
import { useCookies } from 'react-cookie';
import useFlash from './../../../store/flash';

// View components
import Create from './../../../components/admin/users/create';
import { useParams } from 'react-router-dom';
const CreateContainer = (props) => {

    const urlPath = useParams();

    let isUserEdit = true;
    let initialBtnText = "Edit user"
    if(typeof urlPath != "undefined" && urlPath.userRef === 'create'){
        isUserEdit = false;
        initialBtnText = "Create user"
    }

    // State management
    let encSecret = process.env.REACT_APP_ENC_KEY;
    const [loading, setLoading] = useState(false); // eslint-disable-line
    const [rdr, setRdr] = useState(false);
    const [error, setError] = useState(false);
    const [btnTxt, setBtnTxt] = useState(initialBtnText);
    const [settings, setSettings] = useState({ firstName: "", lastName: "", email: "" });
    const [permissions, setPermissions] = useState({ Reports: false, Users: false, Manage: false });
    const [returnEmailLink,setReturnEmailLink] = useState(false)
    const [valErrors, setValErrors] = useState(false);
    const [flashState, flashActions] = useFlash(); // eslint-disable-line
    const [authCookie] = useCookies([process.env.REACT_APP_AUTH_COOKIE]);
    const [userManager,setUserManager] = useState({})

    const [emailLink,setEmailLink] = useState(false)
    const [lightBoxOpen,toggleLightBoxOpen] = useState(false);


    const redirectUrl = "/login";

    // Handle the field updates 
    const handleUpdate = (fieldname, val) => {
        var newSettings = { ...settings };
        newSettings[fieldname] = val;
        setSettings(newSettings);
    }

    const handlePermissionsUpdate = (fieldname, e) => {
        var newSettings = { ...permissions };
        newSettings[fieldname] = e.target.checked;
        setPermissions(newSettings);
    }

    // Submit the details for saving
    const saveUser = ( e ) => {

        e.preventDefault();
        setError(false);
        setValErrors({})

        var nbtn = (isUserEdit) ? "Editing..." : "Creating...";
        setBtnTxt(nbtn);

        let dataToPost = {
            managerId:userManager.ID,
            firstName:settings.firstName,
            lastName:settings.lastName,
            email:settings.email,
            phone:settings.phone,
            returnLink:returnEmailLink,
            permissions:{
                reports:permissions.Reports,
                users:permissions.Users,
                manage:permissions.Manage
            }
        }

        let authInfo = Encryption.extractHeaders(authCookie);

        // var email = encodeURI(settings.email_address.replace("+", "*PLUS*"));

        var url = (isUserEdit) ? '/users/edit/'+urlPath.userRef  : '/users/create';

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + url, {
            method: 'post',
            headers: {
                "Content-type": "application/json; charset=utf-8",
                'api-token': authInfo.apiToken,
                'api-key': authInfo.apiKey
            },
            body: JSON.stringify(dataToPost)
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {
            if(data.rslt === 'success' ){
                if( data.detail != null){
                    setEmailLink(data.detail)
                    toggleLightBoxOpen(true)
                } else {
                    let message = "A new user has been created.  They have been emailed a password create link"
                    
                    if(isUserEdit){
                        message = 'The user has been updated'
                    } 

                    setError({ msg: message, style: "grn" });
                    flashActions.set({ msg: message , style: "grn" });
                    setRdr('/admin/users')
                }
                
            } else if (data.rslt === 'invalid-client-manager'){
                setValErrors(
                    {
                        'manager':['Please select ensure a client is selected'],
                        'client':['Please select ensure a client is selected']
                   }
                );

            } else if (data.rslt === 'usr-valid-error' || data.rslt === "invalid-data"){
                setError({ msg: "Sorry, please ensure the user details are filled out correctly", style: "red" });
                
            }else if (data.rslt === 'save-error'){
                setError({ msg: "Sorry, there was an error saving on the server", style: "red" });
                
            } else if (data.rslt === 'usr-exists'){
                setError({ msg: "Sorry, there was an error", style: "red" });
                setValErrors(
                    {
                        'email':['This email address already exists in an account'],
                   }
                );
            }

            var nbtn = (isUserEdit) ? "Edit" : "Create";

            setBtnTxt(nbtn);


        })
        .catch(function (error) {
            setError({ msg: "Sorry but there was an error creating the user. Please check your data and try again", style: "red" });
        });

    }

    // Load up existing user details
    const loadUsr = () => {
        // Check whether there is an edit param set up
        if (typeof urlPath.userRef == "undefined") {
            return;
        }

        setLoading(true);

        let authInfo = Encryption.extractHeaders(authCookie);
        
        fetch(process.env.REACT_APP_API_BASE + '/users/'+ urlPath.userRef , {
            method: 'get',
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
                'api-token': authInfo.apiToken,
                'api-key': authInfo.apiKey
            },
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {
            let newPermissions = data.Permissions
            setPermissions(newPermissions)

            let newSettings = {
                phone:data.MobileNo,
                email:data.Email,
                firstName:data.FirstName,
                lastName:data.LastName
            }
            setSettings(newSettings)
            setLoading(false);

        })
        .catch(function (error) {
            flashActions.set({ msg: "Sorry but we could not find the user you are looking for. PLease try again.", style: "red" });
            setRdr('/users');
        });        
    }

    const checkUserPermissions = () =>{
        // If there is no cookie, then they are not logged in
        let authInfo = Encryption.extractHeaders(authCookie);

        if(!authInfo.permissions.Users){
            setRdr(redirectUrl)
            return
        } else {
            if(isUserEdit){
                loadUsr();
            }
        }
    }

    /* eslint-disable */
    useEffect(() => {
        checkUserPermissions();
        document.title = 'Create user :: Morel Trust';
    },[])
    /* eslint-enable */

    return (
        <Create
            error={ error }
            valErrors={ valErrors }
            rdr={ rdr }
            handleUpdate={ handleUpdate }
            handlePermissionsUpdate={handlePermissionsUpdate}
            saveUser={saveUser}
            setReturnEmailLink={setReturnEmailLink}
            permissions={permissions}
            settings={settings}
            userManager={userManager}
            setUserManager={setUserManager}
            isUserEdit={isUserEdit}
            btnTxt={ btnTxt }
            emailLink={emailLink}
            lightBoxOpen={lightBoxOpen}
            toggleLightBoxOpen={toggleLightBoxOpen}
        />
    );
}

export default CreateContainer;
