// Core React 
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';

// Components

import BlockBarContainer from '../../containers/admin/blocks/cms/block-bar-container';
import Permissions from '../../utilities/permissions';
import BlocksContainer from '../../containers/pages/blocks-container';
import PublicHeaderContainer from '../../containers/common/public-header-container';

function InfoPage(props) {

    if (props.loading) return "";
console.log(props.authState);
    return (
        <Fragment>
            <Helmet>
                { props.page.subtitle !== "" ? (
                    <title>{props.page.subtitle} :: {props.page.title} :: Morel Trust, Bromley</title>
                ) : (
                    <title>{props.page.title} :: Morel Trust, Bromley</title>
                )}
                <meta name="description" content={props.page.metaDescription} />
                <meta name="keywords" content={props.page.metaKeywords} />
            </Helmet>

            {props.authState && Permissions.actionCheck("admin", "all", props.authState.permissions) &&
                <BlockBarContainer {...props} />
            }

            <PublicHeaderContainer page={props.page} />
            
            {props.page.blocks.map((block, i) =>
                <Fragment key={"block-" + i}>
                    <BlocksContainer block={block} />
                </Fragment>
            )}

        </Fragment>
    );

}

export default InfoPage;
