import React, { Fragment } from 'react';
import ImagesContainer from './../../../../containers/admin/images/images-container';

function EditImg(props) {

    return (
        <Fragment>
            <div className="edit-img" style={ props.imageUrl ? { backgroundImage: 'url(' + props.imageUrl + ')' } : {}}>
                <button className={typeof props.class !== "undefined" ? props.class + " edit-img-btn" : "edit-img-btn"} onClick={(e) => props.toggleImageManager(e, props.size)}>{ "Edit image" }</button>
            </div>

            {props.isOpen &&
                <ImagesContainer {...props} />
            }
        </Fragment>
    );
}

export default EditImg;
